import { Form, Formik } from "formik"
import React, { useEffect } from "react"
import Button from "../../../../../components/form/button/button"
import TextField from "../../../../../components/form/textfield/textfield"
import "./risk-assessment-info.scss"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { editRiskAssessment, getRiskAssessmentInfo, getRiskAssessments } from "../../../../../redux/risk-assessment/riskAssessmentSlice"
import * as Yup from 'yup';
import { getRiskAssessmentInfoStateSelector } from "../../../../../redux/selectors/riskAssessment"
import { changeDateFormat } from "../../../../../utils/DateUtils"
import { getLoginStateSelector } from "../../../../../redux/selectors/loginSelector"
import { getUserRoleName } from "../../../../../utils/constants"

const RiskAssessmentInfo = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const raData = location?.state?.selectedRiskAssessment
  const raIData = useSelector(getRiskAssessmentInfoStateSelector())
  const loginState = useSelector(getLoginStateSelector())
  const { isLoggedIn, loggedInUser } = loginState

  useEffect(() => {
    dispatch(getRiskAssessmentInfo(raData?.id))
  }, [])
  const initialValues = {
    name: raIData?.title,
    riskAssessmentNo: raIData?.risk_assessment_no,
    created_date: changeDateFormat(raIData?.created_date, "YYYY-MM-DD", "MM/DD/YYYY"),
    updated_date: changeDateFormat(raIData?.updated_date, "YYYY-MM-DD", "MM/DD/YYYY"),
    models: raIData.models,
    facilitator: raIData?.facilitator ?? ""
  }
  const validate = Yup.object({
    name: Yup.string().required("Name is Required")
  })
  const handleSubmit = payload => {
    const id = raData?.id
    dispatch(
      editRiskAssessment({
        id,
        payload,
        callback: resp => {
          fetchRiskAssessments({
            productLineId: location.state?.selectedProductLine?.id,
            modelId: location.state?.selectedModelId
          })
        }
      })
    )
  }
  const fetchRiskAssessments = ({ productLineId, modelId }) => {
    if (modelId) {
      dispatch(getRiskAssessments({ modelId: modelId, config: {} }))
    } else if (productLineId) {
      dispatch(getRiskAssessments({ productLineId: productLineId, config: {} }))
    }
  }

  return (
    <div className="risk-assessment-info-wrapper">
      <div className="heading">Risk assessment information</div>
      <hr />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validate}
        enableReinitialize
      >
        {({ formik }) => (
          <Form>
            <div className="risk-assessment-form">
              <TextField type="text" name="name" placeholder="Title" label={'Title'} />
              <TextField
                type="text"
                name="created_date"
                placeholder="Created Date"
                label={"Created Date"}
                disabled
              ></TextField>
            </div>
            <div className="risk-assessment-form">
              <TextField
                type="text"
                name="updated_date"
                placeholder="Updated Date"
                label={"Updated Date"}
                disabled
              />
              <TextField
                type="text"
                name="models"
                placeholder="Models"
                label={"Models"}
                disabled
              />
            </div>
            <div className="more-risk-assessment">
              <TextField
                type="text"
                name="facilitator"
                placeholder="Facilitator"
                label={"Facilitator"}
              />
            </div>
            <div className="action-buttons">
            {getUserRoleName(loggedInUser?.role) === "admin" ? <Button type="submit" variant="secondary-button">
                Save
              </Button> : <></> }
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default RiskAssessmentInfo
