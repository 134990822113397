import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {assets} from '../../../../../utils/assetsSrc';
import TextField from '../../../../../components/form/textfield/textfield';
import MultiSelectDropdown from '../../../../../components/form/multiSelectDropdown/MultiSelectDropdown';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import SingleSelectDropdown from '../../../../../components/form/singleSelectDropdown/singleSelectDropdown';
import Button from '../../../../../components/form/button/button';
import {useSelector} from 'react-redux';
import {
  getAllRiskAssessmentStateSelector,
  getRiskAssessmentStateSelector,
} from '../../../../../redux/selectors/riskAssessment';
import {
  eventRowInfoIconEnum,
  lifeCyclePhasesEnum,
  riskAssessmentAccessIds,
  riskAssessmentAccessNames,
  taskFrequencyEnum,
} from '../../../../../utils/constants';
import {apiFunctions} from '../../../../../api/functions';
import {Checkbox} from '@mui/material';
import './addEditTask.scss';
import ViewInfo from '../view-info/view-info';
import {useLocation} from 'react-router-dom';

export default function AddEditTask({
  label,
  show,
  setShow,
  prefilledData,
  handleTaskCreation,
}) {
  const location = useLocation();
  const selectedRA = location?.state?.selectedRiskAssessment;
  const riskAssessmentState = useSelector(getAllRiskAssessmentStateSelector());
  const [riskAssesmentsList, setRiskAssesmentsList] =
    useState(riskAssessmentState);
  // const [subCategoriesList, setSubCategoriesList] = useState([])
  const [taskAccess, setTaskAccess] = useState(true);
  const initialValuesObj = prefilledData || {
    taskName: '',
    riskAssessmentList: riskAssesmentsList.filter(
      item => item.id == selectedRA.id,
    ),
    taskDetails: '',
    lifeCyclePhases: lifeCyclePhasesEnum[0],
    //subCategories: '',
    task_sub_categories:'',
    taskFrequency: taskFrequencyEnum[0],
    liveWork: false,
  };
  const [initialValues, setInitialValues] = useState(initialValuesObj);

  const validate = Yup.object({
    riskAssessmentList: Yup.array().min(1,'Risk assessment is required'),
    taskName: Yup.string().required('Task Name is Required'),
    taskDetails: Yup.string().required('Task Details is Required'),
   // task_sub_categories: Yup.string().required('Task subcategory is Required')
  });

  useEffect(() => {
    setRiskAssesmentsList(riskAssessmentState);
    //getSubCategories()
  }, []);

  // const getSubCategories = () => {
  //     apiFunctions.getSubCategoriesForTask().then(resp => {
  //         if (resp.status === 200 && resp.data.length) {
  //             setSubCategoriesList(resp.data)
  //             const vals = {...initialValues}
  //             vals.subCategories = resp.data[0]
  //             setInitialValues(vals)
  //         }
  //     })
  // }

  const handleSubmit = vals => {
    const taskPayload = {
      name: vals.taskName || '',
      details: vals.taskDetails || '',
      risk_assessment: vals.riskAssessmentList.map(el => el.id) || [],
      life_cycle_phase: vals.lifeCyclePhases.id || '',
      // sub_categories: vals.subCategories.id || 0,
      task_sub_categories: vals.task_sub_categories || '',
      frequency: vals.taskFrequency.id,
      access: taskAccess ? 1 : 2,
      live_work: vals.liveWork || false,
      note: 'Sample',
    };
    handleTaskCreation(taskPayload);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="confirm-cancel"
      centered
      size="lg"
      className="new-risk-assessment-task">
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleSubmit}>
          {({formik, values, setFieldValue, isSubmitting, isValid, errors}) => (
            <Form>
              <div className="risk-assessment-multiselect">
                <MultiSelectDropdown
                  label={'Risk assessment'}
                  listItems={riskAssesmentsList}
                  name="riskAssessmentList"
                  value={values.riskAssessmentList}
                  renderChipsInside={true}
                  handleSelectedUsersChange={val =>
                    setFieldValue('riskAssessmentList', val)
                  }
                  showError={true}
                />
                {/* <span style={{ textTransform: 'capitalize' }} className='task-access' onClick={() => setTaskAccess(!taskAccess)}>
                                    {riskAssessmentAccessNames[taskAccess ? '1' : '2']}
                                    <img src={taskAccess ? assets.closedLock : assets.openLock} />
                                </span> */}
              </div>
              <div>
                <TextField
                  name="taskName"
                  type="text"
                  label="Task name"
                  placeholder="Risk assessment task"
                  maxLength={100}
                />
              </div>
              <div className="mb-2">
                <TextField
                  name="taskDetails"
                  type="text"
                  label="Task details"
                  isMultiline={true}
                  textAreaStyle={{height:100}}
                  styles={{height:100}}
                  placeholder="Enter task details"
                />
              </div>
              <div className="more-task-details">
                <SingleSelectDropdown
                  label={'Life cycle phases'}
                  listItems={lifeCyclePhasesEnum}
                  name="lifeCyclePhases"
                  value={values['lifeCyclePhases']}
                  handleSelectedUsersChange={val => {
                    setFieldValue('lifeCyclePhases', val);
                  }}
                />
                {/* <SingleSelectDropdown label={'Add task subcategories'} listItems={subCategoriesList} name='subCategories'
                                    value={values['subCategories']} handleSelectedUsersChange={(val) => { setFieldValue('subCategories', val) }} /> */}
                <div className="mb-2">
                  <TextField
                    name="task_sub_categories"
                    type="text"
                    label="Task subcategories"
                    placeholder="Add task subcategories"
                    styles={{height:56}}
                  />
                </div>
                <SingleSelectDropdown
                  label={'Task frequency'}
                  listItems={taskFrequencyEnum}
                  name="taskFrequency"
                  value={values['taskFrequency']}
                  handleSelectedUsersChange={val => {
                    setFieldValue('taskFrequency', val);
                  }}
                />
              </div>
              <div className="action-row">
                <div className="live-work">
                  <Checkbox
                    checked={values['liveWork']}
                    onChange={e => setFieldValue('liveWork', e.target.checked)}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                  Live work
                </div>
                <div className="info-events">
                  <ViewInfo label={eventRowInfoIconEnum.live_work} />
                </div>
                <div className="action-buttons">
                  <Button
                    type="button"
                    clickHandler={() => {
                      setFieldValue('riskAssessmentList', []);
                      setFieldValue('taskName', '');
                      setFieldValue('taskDetails', '');
                      setFieldValue('lifeCyclePhases', lifeCyclePhasesEnum[0]);
                      setFieldValue('subCategories', {});
                      setFieldValue('taskFrequency', taskFrequencyEnum[0]);
                    }}
                    variant="secondary-button">
                    Reset
                  </Button>
                  <Button type="submit" variant="submit-button">
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
