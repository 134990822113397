export const allRoutes = {
    login: '/login',
    signup: '/signup',
    resetPassword: '/password-reset',
    home: '/home',
    shareRiskAssessment:'/share-risk-assessment/:RAId',
    feedback: '/feedback',
    dashboardRoutes: {
        index: '/dashboard',
        assessment: '/dashboard/assessment',
        userList: {
            index: '/dashboard/user-list',
            main: '/dashboard/user-list/main',
            productLine: '/dashboard/user-list/main/product-line',
            restrictedRA: '/dashboard/user-list/main/restrited-risk-assessments',
        },
        knowledgeHub: '/dashboard/knowledge-hub',
        assessmentRoutes: {
            productLine: '/dashboard/assessment/product-line',
            productModels: '/dashboard/assessment/product-models',
            riskAssessments: '/dashboard/assessment/risk-assessments',
            specificRiskAssessment: '/dashboard/assessment/risk-assessment',
            taskDetails: '/dashboard/assessment/task-detail',
            eventDetails: '/dashboard/assessment/event-detail',
            limitsOfMachine: '/dashboard/assessment/machine-limits',
            riskAssessmentInfo: '/dashboard/assessment/risk-assessment-info',
            assumptions: '/dashboard/assessment/assumptions',
            revisionHistory: '/dashboard/assessment/revisionHistory',
            attendanceRecord: '/dashboard/assessment/attendanceRecord',
            summary: '/dashboard/assessment/summary',
        },
        deletedAssessment: '/dashboard/deleted-assessment',
        changePassword: '/dashboard/change-password'

    }
}