import React from 'react'
import { assets } from '../../../../../utils/assetsSrc'
import { useLocation, useNavigate } from 'react-router-dom'
import { allRoutes } from '../../../../../utils/routeConstants'
import './risk-assessment-nested-tabs.scss'

export default function RiskAssessmentNestedTabs() {
    const location = useLocation()
    const nav = useNavigate()
    const tabsList = [
        {
            icon:'',
            label:'Summary',
            link: allRoutes.dashboardRoutes.assessmentRoutes.summary
        },
        {
            icon:'',
            label:'Attendance record',
            link: allRoutes.dashboardRoutes.assessmentRoutes.attendanceRecord
        },
        {
            icon:'',
            label:'Risk assessment info',
            link: allRoutes.dashboardRoutes.assessmentRoutes.riskAssessmentInfo
        },
        {
            icon:'',
            label:'History',
            link: allRoutes.dashboardRoutes.assessmentRoutes.revisionHistory
        },
        {
            icon:'',
            label:'Limits of machine',
            link: allRoutes.dashboardRoutes.assessmentRoutes.limitsOfMachine
        },
        {
            icon:'',
            label:'Assumptions',
            link: allRoutes.dashboardRoutes.assessmentRoutes.assumptions

        }
    ]
    return (
        <div className='ra-nested-tabs-wrapper'>
            {tabsList.map(tab=>
                <span  key ={tab.label}className='nested-tab' onClick={()=>nav(tab.link,{state:{...location.state}})}>
                    <img src={assets.clipboard}/>
                    {tab.label}
                </span>)}
        </div>
    )
}
