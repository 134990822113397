import React from 'react'
import { assets } from '../../utils/assetsSrc'
import './customSearch.scss'

export default function CustomSearch({ height, width, onChange }) {
  return (
    <div style={{ height, width }} className='search-wrapper'>
      <input type='text' onChange={onChange} placeholder='Search'/>
      <img className='search-img' src={assets.search} />
    </div>
  )
}
