import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import './pagination.scss'
import { assets } from '../../utils/assetsSrc';

export default function Pagination({ pageSizeOptions, totalCount,
     currentPage, nextAndPrev, handlePageNavigation = () =>{},
      handlePageSizeChangeHandler }) {
    const [selectedPageSize, setSelectedPageSize] = useState(pageSizeOptions[0])
    const [pageInfo, setPageInfo] = useState('')

    useEffect(() => {
        let lower = currentPage === 1 ? 1 : (currentPage-1)*selectedPageSize
        let upper = currentPage*selectedPageSize
        if(upper>totalCount){
            upper = totalCount
        }
        let text = `${lower}-${upper} of ${totalCount}`
        setPageInfo(text)
    }, [currentPage,nextAndPrev])


    const handlePageSizeChange = (option) => {
        setSelectedPageSize(option)
        handlePageSizeChangeHandler(option)
    }

    return (
        <div className='pagination-wrapper'>
            <div className='row-options'>
                <span className='label'>Rows per page:</span>
                <Dropdown onSelect={handlePageSizeChange}>
                    <Dropdown.Toggle variant={'secondary'} id="dropdown-basic">
                        {selectedPageSize}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {pageSizeOptions.map(option =>
                            <Dropdown.Item eventKey={option}>{option}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='page-info'>
            {pageInfo}
            </div>
            <div className='nav-actions'>
                <img className={!nextAndPrev.prev?'disable-nav':''} onClick={()=>handlePageNavigation('prev')} src={assets.previous}/>
                <img className={!nextAndPrev.next?'disable-nav':''} onClick={()=>handlePageNavigation('next')} src={assets.next}/>
            </div>
        </div>
    )
}
