import React from 'react';
import { useNavigate } from 'react-router-dom';

import './footer.scss';

export default function Footer({showContent}) {
  const nav = useNavigate();
  return (
    <div className='footer-wrapper'>
        <nav>
            <ul className='footer-content'>
                {/* <li onClick={()=>nav(`/home/contact`)}>Contact us</li>
                <li> <a href="https://www.komatsu.com/en/privacy-statement/" target="_blank" className='link'>Terms and Conditions</a></li> */}
                <li> <a href="https://www.komatsu.com/en/privacy-statement/" target="_blank" className='link'>Privacy Policy</a></li>
            </ul>
        </nav>
    </div>
  )
}
