import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { combineReducers } from '@reduxjs/toolkit'
import rootSaga from './rootSaga';
import storage from 'redux-persist/lib/storage'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

// import the application reducers
import loginReducer from './login/loginSlice';
import riskAssessmentReducer from './risk-assessment/riskAssessmentSlice';
import utilityReducer from './utility/utilitySlice';
import signupReducer from './signup/signupSlice';
import userListReducer from './user-list/userListSlice';
import riskAssessmentCoreReducer from './risk-assessment-core/riskAssessmentCoreSlice';
import feedbackReducer from './feedback/feedbackSlice';
import passwordReducer from './password/passwordSlice';
import termsConditionsReducer from './termsConditions/termsConditionsSlice'

// persisted storage config
const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [],
}

// using saga middleware for handling asynchronous operations
const sagaMiddleware = createSagaMiddleware();

export const rootReducers = combineReducers({
    loginReducer,
    utilityReducer,
    signupReducer,
    riskAssessmentReducer,
    userListReducer,
    riskAssessmentCoreReducer,
    feedbackReducer,
    passwordReducer,
    termsConditionsReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducers)
// configure the reducers for different features
const store = configureStore({
    reducer: {
        // add all reducers here
        persistedReducer
    },
    devTools:true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    },
    ).concat(sagaMiddleware)
})

// add root saga here
sagaMiddleware.run(rootSaga)

export default store;
export const AppState = store.getState;
export const AppDispatch = store.dispatch;