import React, { useEffect, useState } from "react"
import "./event-details.scss"
import AddNote from "../../components/add-note/add-note"
import CheckBoxWithLabel from "./check-box-with-label"

const EventAction = ({ setFieldValue, eventStatus, noteValue, readOnly }) => {
  const [showRemoveNoteInput, setShowRemoveNoteInput] = useState(false)
  const [completedStatus, setCompletedStatus] = useState(eventStatus == 1 || eventStatus == 3)
  const [removedStatus, setRemovedStatus] = useState(eventStatus == 2 || eventStatus == 3)

  useEffect(() => {
    if(completedStatus && removedStatus){
      setFieldValue("event_status", 3)
    }
    else if(completedStatus){
      setFieldValue("event_status", 1)
    }
    else if(removedStatus){
      setFieldValue("event_status", 2)
    }
  },[completedStatus, removedStatus])

  const handleOnChange = (value, isChecked) => {
    if(isChecked){
      value == 1 ? setCompletedStatus(true) : setRemovedStatus(true)
    }
    else{
      value == 1 ? setCompletedStatus(false) : setRemovedStatus(false)
    }
  }

  return (
    <div>
      <div className="heading">Event has been:</div>
      <div className="d-flex">
        <CheckBoxWithLabel
          label={"Completed"}
          value={completedStatus}
          onChange={e =>
            handleOnChange('1',e.target.checked)
          }
          disabled={readOnly}
        />
        <CheckBoxWithLabel
          label={"Removed"}
          value={removedStatus}
          onChange={e =>
            handleOnChange('2', e.target.checked)
          }
          disabled={readOnly}
        />
      </div>
      {eventStatus == 2 && (
        <AddNote
          showNoteInput={showRemoveNoteInput}
          setShowNoteInput={setShowRemoveNoteInput}
          title={"Add Removed notes"}
          name={"removed_notes"}
          value={noteValue}
          setFiledValue={setFieldValue}
          readOnly={readOnly}
        />
      )}
    </div>
  )
}
export default EventAction
