import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form, ErrorMessage} from 'formik';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

// All styles and static import goes here
import {assets} from '../../utils/assetsSrc';
import constants from '../../constants/loginSignup.constants';

// import login actions
import {initLogin} from '../../redux/login/loginSlice';
import {
  getLoginStateSelector,
  loginState,
} from '../../redux/selectors/loginSelector';

// import all custom components
import SplashWrapper from '../../components/splash/splash-wrapper';
import LoginPopup from './loginPopup';
import TextField from '../../components/form/textfield/textfield';

import './login.scss';
import Button from '../../components/form/button/button';
import Loader from '../../components/loader/loader';
import {allRoutes} from '../../utils/routeConstants';
import Confirmation from '../../components/confirmation/confirmation';

export default function Login() {
  // const appState = useSelector(state => state.persistedReducer.loginReducer);
  const loginState = useSelector(getLoginStateSelector());
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const location = useLocation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const {isLoggedIn} = loginState;

  // check for LoggedIn State and navigate
  useEffect(() => {
    if (isLoggedIn) {
      setShowConfirmationPopUp(true);
    }
  }, [isLoggedIn]);

  const handleTnCAccept = () => {
    if (location?.state?.lastNavLink) {
      nav(allRoutes.dashboardRoutes.assessmentRoutes.specificRiskAssessment, {
        state: {...location.state},
      });
    } else {
      nav('/dashboard');
    }
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const formFieldStyles = {
    // height: '48px',
    // width: '400px'
  };

  const validate = Yup.object({
    email: Yup.string().email('Email is invalid!').required('Email Required!'),
    password: Yup.string().required('Password Required!'),
  });

  // const handleLogin = () => {
  //   dispatch(initLogin({ userName: 'shivam', password: 'Hello@1234' }))
  // }

  const handleLoginFormSubmit = values => {
    const payload = {
      username: values.email,
      password: values.password,
    };
    dispatch(initLogin(payload));
  };

  const LoginForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleLoginFormSubmit}>
          {formik => (
            <Form>
              <div className="loginForm">
                <div className="login-heading">Login</div>
                <div className="login-quote">{constants.loginQuote}</div>
                <TextField
                  type="text"
                  label="Email ID"
                  name="email"
                  placeholder="Enter email"
                  styles={formFieldStyles}
                  suffixIcon="email"
                />
                <TextField
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Enter password"
                  styles={formFieldStyles}
                  suffixIcon="closeEye"
                />
                <div className="forgot-password">
                  <span onClick={() => nav('/password-reset')}>
                    Forgot Password
                  </span>
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="submit-button"
                    styles={formFieldStyles}
                    // clickHandler={()=>handleLoginFormSubmit()}
                  >
                    Login
                  </Button>
                </div>
                <div className="register-account">
                  <span className="first">Don’t have an Account? &nbsp;</span>
                  <span onClick={() => nav('/signup')} className="second">
                    Register
                  </span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {loginState.isLoading && <Loader />}
      </div>
    );
  };

  return (
    <>
      <SplashWrapper>
        <div className="login-wrapper">
          <img className="logo" src={assets.komatsuLogo} />
          <LoginForm />
          {showConfirmationPopUp && (
            <Confirmation
            label={'Terms and Condition'}
            confirmationText={
                'Your use of this Komatsu Risk Assessment Application111 and access to any Komatsu confidential and proprietary information available through the Application, are subject to Komatsu`s Worldwide Code of Business Conduct and related company policies, including those relating to confidentiality of information, conflicts of interest, and appropriate use of company electronic communications tools. As a Komatsu employee, you are bound to follow these guidelines while using the Application. Violation of these policies, concealment of violations, as well as alteration, falsification, forgery or misrepresentation of facts related to any violations may result in appropriate sanctions and disciplinary actions. This may include termination of employment. In some cases, Komatsu may report violations to appropriate law enforcement authorities where a violation may also be a violation of the law'
              }
              show={showConfirmationPopUp}
              setShow={setShowConfirmationPopUp}
              primaryButtonText="Ok"
              primaryButtonClickHandler={handleTnCAccept}
            />
          )}
        </div>
      </SplashWrapper>
    </>
  );
}
