// Images imports
import komatsuSplash from "../assets/images/komatsu-splash.png"
import komatsuLogo from "../assets/images/svg/komatsu-logo.svg"
import carouselMock from "../assets/images/carousel_mock.png"
import videoPlayerIcon from "../assets/images/video-player-icon.png"
import documentIcon from "../assets/images/document-icon.png"
import pdfIcon2 from "../assets/images/pdf-icon-2.png"
import excelIcon2 from "../assets/images/excel-icon.png"

// Icons Imports
import EmailAt from "../assets/icons/Union.svg"
import successIcon from "../assets/icons/check-square.svg"
import Tnc from "../assets/icons/Tnc.svg"
import lock from "../assets/icons/lock.svg"
import closeEye from "../assets/icons/close-eye.svg"
import openEye from "../assets/icons/open-eye.svg"
import uploadIcon from "../assets/icons/upload-icon.svg"
import errorTriangle from "../assets/icons/exclamation-triangle.svg"
import successBlue from "../assets/icons/success-blue.svg"
import profileIcon from "../assets/icons/profile-icon.svg"
import pdfIcon from "../assets/icons/pdf-icon.svg"
import sideNavList from "../assets/icons/sidenav-list-icon.svg"
import logout from "../assets/icons/logout-icon.svg"
import search from "../assets/icons/search.svg"
import deleteIcon from "../assets/icons/delete-icon.svg"
import editIcon from "../assets/icons/edit-icon.svg"
import backArrow from "../assets/icons/back-arrow.svg"
import closeIcon from "../assets/icons/close-icon.svg"
import previous from "../assets/icons/previous.svg"
import next from "../assets/icons/next.svg"
import unlockIcon from "../assets/icons/unlock-icon.svg"
import moreIcon from "../assets/icons/more-menu.svg"
import excelIcon from "../assets/icons/excel-icon.svg"
import infoIcon from "../assets/icons/info-icon.svg"
import raAccessPrivate from "../assets/icons/ra-access-private.svg"
import chevronRight from "../assets/icons/chevron-right.svg"
import clipboard from "../assets/icons/clipboard.svg"
import openLock from "../assets/icons/open-lock.svg"
import closedLock from "../assets/icons/closed-lock.svg"
import copyIcon from "../assets/icons/copy-icon.svg"
import trash from "../assets/icons/trash.svg"
import editIcon2 from "../assets/icons/edit-2.svg"
import lockFilled from "../assets/icons/lock-filled.svg"
import shareIcon from "../assets/icons/share.svg"
import plusCircle from "../assets/icons/plus-circle.svg"
import addFilled from "../assets/icons/add-filled.svg"
import saveIcon from "../assets/icons/save-icon.svg"
import checkIcon from "../assets/icons/check-icon.svg"
import filterIcon from "../assets/icons/filter-icon.svg"
import eyeIcon from "../assets/icons/eyeIcon.svg"
import replyIcon from "../assets/icons/replyIcon.svg"
import viewMedia from "../assets/icons/view-media.svg"
import infoIcon2 from "../assets/icons/info-icon-2.svg"

//Mocks
import wheelLoader from "../assets/images/pl_wheel-loader.png"
import actionRequire from "../assets/icons/exclamation-triangle-fill.svg"
import sortIcon from "../assets/icons/sort-down.svg"
import taskNotStarted from "../assets/icons/status_not_started.svg"
import taskInProgress from "../assets/icons/status_in_progress.svg"
import taskCompleted from "../assets/icons/status_completed.svg"

export const assets = {
  // Images
  komatsuSplash,
  komatsuLogo,
  carouselMock,
  videoPlayerIcon,
  documentIcon,
  pdfIcon2,
  excelIcon2,

  // Icons
  EmailAt,
  successIcon,
  Tnc,
  lock,
  closeEye,
  openEye,
  uploadIcon,
  errorTriangle,
  successBlue,
  profileIcon,
  pdfIcon,
  sideNavList,
  logout,
  search,
  deleteIcon,
  editIcon,
  backArrow,
  closeIcon,
  previous,
  next,
  unlockIcon,
  moreIcon,
  excelIcon,
  infoIcon,
  raAccessPrivate,
  chevronRight,
  clipboard,
  openLock,
  closedLock,
  copyIcon,
  trash,
  editIcon2,
  lockFilled,
  shareIcon,
  plusCircle,
  addFilled,
  saveIcon,
  checkIcon,
  filterIcon,
  eyeIcon,
  replyIcon,
  infoIcon2,

  //mocks
  wheelLoader,
  viewMedia,
  actionRequire,
  sortIcon,
  taskNotStarted,
  taskInProgress,
  taskCompleted
}
