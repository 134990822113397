import moment from 'moment';

// Date formats used in application


/**
 * Method to get date in string.
 * @param date is type of Date
 * @param format is type of DateFormat.
 * @returns date string
 */
export const getDateInFormat = (
  date = moment().toDate(),
  format,
) => {
  return moment(date).format(format);
};

export function changeDateFormat(
  date,
  fromFormat,
  toFormat,
) {
  return moment(date, fromFormat).local().format(toFormat);
}

export function getDate(date, fromFormat) {
  return moment(date, fromFormat).toDate();
}
