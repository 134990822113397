import React, { useEffect, useState } from 'react';
import { assets } from '../../utils/assetsSrc';
import './sidebar.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/login/loginSlice';
import { allRoutes } from '../../utils/routeConstants';
import { Modal } from 'react-bootstrap';
import Button from '../form/button/button';
import { useSelector } from 'react-redux';
import { getLoginStateSelector } from '../../redux/selectors/loginSelector';
import { sideBarTabsNoAccess } from '../../utils/constants';

const navItemsMainArr = [
    { id: 'assessment', name: 'Risk Assessments', icon: assets.sideNavList, navigationTo: allRoutes.dashboardRoutes.assessment },
    // { id: 'article', name: 'Training', icon: assets.sideNavList, navigationTo: allRoutes.dashboardRoutes.knowledgeHub },
    { id: 'feedback', name: 'Feedback', icon: assets.sideNavList, navigationTo: allRoutes.feedback },
];
const navItemsManageArr = [
    { id: 'userlist', name: 'User List', icon: assets.sideNavList, navigationTo: allRoutes.dashboardRoutes.userList.main },
    { id: 'changePassword', name: 'Change Password', icon: assets.sideNavList, navigationTo: allRoutes.dashboardRoutes.changePassword },
    // { id: 'role', name: 'Role', icon: assets.sideNavList, navigationTo: '' },
    // { id: 'productLineAccess', name: 'Product line Access', icon: assets.sideNavList, navigationTo: '' },
    // { id: 'restrictedRiskAssessments', name: 'Restricted Risk Assessments', icon: assets.sideNavList, navigationTo: '' },
    { id: 'deleted', name: 'Deleted Risk Assessments', icon: assets.sideNavList, navigationTo: allRoutes.dashboardRoutes.deletedAssessment },
];

export default function Sidebar() {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const loginState = useSelector(getLoginStateSelector());
    const [logoutConfirm, setLogoutConfirm] = useState(false)
    const [navItemsMain, setNavItemsMain] = useState(navItemsMainArr)
    const [navItemsManage, setNavItemsManage] = useState(navItemsManageArr)

    useEffect(()=>{
        const role = loginState?.loggedInUser?.role
        const newManageTabs = navItemsManage.filter(tab=>!sideBarTabsNoAccess[role].includes(tab.id))
        setNavItemsManage(newManageTabs)
    },[])

    const handleLogout = () => {
        dispatch(logout());
        setTimeout(() => {
            localStorage.clear();
        }, 1000)
        nav('/home')
    }
    return (
        <div className='sidebar-wrapper'>
            <div className='sidebar-nav'>
                <nav className=''>
                    <ul className='sidebar-nav-items'>
                        {navItemsMain.map(listItem =>
                            <li key={listItem.id}>
                                <NavLink style={{ display: 'flex', alignItems: 'center' }} className={(navData) => (navData.isActive ? 'active' : 'link')}
                                    to={listItem.navigationTo}>
                                    <span><img src={listItem.icon} /></span>
                                    <span className='name'>{listItem.name}</span>
                                </NavLink>
                            </li>
                        )}
                        <div className='sidebar-heading'>Manage</div>
                        {navItemsManage.map(listItem =>
                            <li key={listItem.id}>
                                <NavLink style={{ display: 'flex', alignItems: 'center' }} className={(navData) => (navData.isActive ? 'active' : 'link')}
                                    to={listItem.navigationTo}>
                                    <span><img src={listItem.icon} /></span>
                                    <span className='name'>{listItem.name}</span>
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            <div className='logout-icon'>
                <span className='logout-button' onClick={()=>setLogoutConfirm(true)}><span className='logout-icon-image'><img src={assets.logout} /></span><span>Logout</span></span>
            </div>
            <Modal
                show={logoutConfirm}
                onHide={() => setLogoutConfirm(false)}
                aria-labelledby="confirm-logout"
                centered
                className="confirm-logout-modal"
            >
                <Modal.Body className="p-5 px-5 text-center">
                    <div className='header'>
                        <p className="label mt-3 mb-4">{'Confirm Logout'}</p>
                    </div>
                    <div className='main-text'>Are you sure, you want to logout?</div>
                    <div className="action-buttons">
                        <Button type='button' variant='submit-button' clickHandler={() => setLogoutConfirm(false)} >Cancel</Button>
                        <Button type='button' variant='secondary-button' clickHandler={handleLogout}>Confirm</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
