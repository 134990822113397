import React from "react"
import "./event-details.scss"
import ViewInfo from "../../components/view-info/view-info"
import TextField from "../../../../../components/form/textfield/textfield"
import { eventDetailInfoIconEnum } from "../../../../../utils/constants"


const ProtectiveMeasure = ({setFieldValue,readOnly}) => {
  const protectiveMeasureInputStyle = {
    height: 88,
    marginTop: 20
  }

  const rightElementStyles = {
    position: "absolute",
    right: 25,
    top: 15
  }

  return (
    <div>
      <div className="heading mb-3"> Protective measures</div>
      <TextField
        name="inherently_measures"
        type="text"
        label="Inherently Safe Design Measures"
        disabled={readOnly}
        placeholder="Inherently Safe Design Measures"
        styles={protectiveMeasureInputStyle}
        rightElement = {
          <ViewInfo label={eventDetailInfoIconEnum.protective_measure.inherently_safe_design_measure}/>
        }
        rightElementStyles={rightElementStyles}
        isMultiline={true}
      />

      
<TextField
        name="safeguarding"
        type="text"
        label="Safeguarding"
        disabled={readOnly}
        placeholder="Safeguarding"
        styles={protectiveMeasureInputStyle}
        rightElement = {
          <ViewInfo label={eventDetailInfoIconEnum.protective_measure.safeguarding}/>
        }
        rightElementStyles={rightElementStyles}
        isMultiline={true}
      />
      <TextField
        name="info_for_use"
        type="text"
        label="Information for Use"
        disabled={readOnly}
        placeholder="Information for Use"
        styles={protectiveMeasureInputStyle}
        rightElement = {
          <ViewInfo label={eventDetailInfoIconEnum.protective_measure.information_for_use}/>
        }
        rightElementStyles={rightElementStyles}
        isMultiline={true}
      />
    </div>
  )
}

export default ProtectiveMeasure
