import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
        changePassword: {
            type: '',
            success: '',
            fail: ''
         }
}

const passwordSlice = createSlice({
    name: "password",
    initialState,
    reducers: {
        changePassword: state => {
            state.isLoading = true
        },
        changePasswordSuccess: (state, action) => {
            state.isLoading = false
            toast.success("Password Change Successfully", {
                autoClose: 2000,
                className: "toast-success"
            })
        },
        changePasswordFailure: (state, action) => {
            state.isLoading = false
            const response = action.payload
            toast.error(response.message ,{autoClose: 2000,className: "toast-error"})
        }
    }
})

export default passwordSlice.reducer

export const {

    changePassword,
    changePasswordSuccess,
    changePasswordFailure

} = passwordSlice.actions