const constants = {
    // login
    loginQuote: 'Login with your credential that you enter during registration',

    // signup

    // forgot password
    emailQuote: 'Kindly provide your email address.',
    verificationText: 'Please enter the verification code that was sent to your email address.',
    changePassword: 'Please enter the new Password',
    changePasswordSuccess:'Your password has been reset successfully',
}

export default constants;