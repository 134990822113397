import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Loader from '../../../../../components/loader/loader';
import { apiFunctions } from '../../../../../api/functions';

const filter = createFilterOptions();

export default function SearchSelect({handleListChange}) {
    const [value, setValue] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [config, setConfig] = React.useState({ params: { search: '' } })
    const loadingSpinner = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    React.useEffect(() => {
        getSubCategories()
    }, [])
    React.useEffect(() => {
        getSubCategories()
    }, [config])
    
    React.useEffect(() => {
        const newSubCat = value.find(el => el.hasOwnProperty('inputValue'))
        const existingData = options.filter(el => el.hasOwnProperty('id'))
        if(newSubCat?.inputValue && !options.find(el=>el.name===newSubCat.inputValue)?.name){
            setIsLoading(true)
            apiFunctions.createSubCategoriesForEvents({name:newSubCat.inputValue}).then((resp)=>{
                if(resp?.data?.id){
                    toast.success('Event Subcategory created Successfully', { autoClose: 2000, className: 'toast-success' })
                    existingData.push(resp.data)
                    handleListChange([...value.filter(el => !el.hasOwnProperty('inputValue')),resp.data])
                }
            }).catch(err=>{
                toast.error('Event Sub category failed',{className:'toast-fail'})
                handleListChange(value.filter(el => !el.hasOwnProperty('inputValue')))
            }).finally(()=>{
                setIsLoading(false)
            })
        }
        handleListChange(value)
        setOptions(existingData)
        
    }, [value])

    const getSubCategories = () => {
        setIsLoading(true)
        apiFunctions.getSubCategoriesForEvents(config).then(resp=>{
            setOptions(resp.data)
        }).catch(err=>{}).finally(()=>setIsLoading(false))
    }

    return (
        <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
            {isLoading && <span style={loadingSpinner}>Loading...</span>}
            <Autocomplete
                value={value}
                multiple
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            name: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            name: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            id: 'TEST21120',
                            inputValue,
                            name: `Add "${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableCloseOnSelect
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                renderOption={(props, option, { selected }) =>
                    <li {...props} key={option.id}>
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                    </li>
                }
                sx={{ width: 300 }}
                
                renderInput={(params) => (
                    <TextField {...params} label="Select Subcategory" />
                )}
            />
        </div>
    );
}
