import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getProductModels,
  clearProductModelsData,
  createRiskAssessment,
  createProductModel,
  editProductModel,
  deleteProductModel
} from "../../../../../redux/risk-assessment/riskAssessmentSlice"
import { getproductModelStateSelector } from "../../../../../redux/selectors/riskAssessment"
import {
  getItemFromSession,
  sessionAttributes
} from "../../utils/sessionStorage"
import Button from "../../../../../components/form/button/button"
import ProductModelCard from "../../components/product-model-card/productModelCard"
import NewRiskAsessment from "../../components/new-risk-assessment/newRiskAsessment"
import { useNavigate } from "react-router-dom"
import { allRoutes } from "../../../../../utils/routeConstants"
import "./productModel.scss"
import { rbac } from "../../../../../utils/constants"
import { getLoginStateSelector } from "../../../../../redux/selectors/loginSelector"
import AddEditProductModel from "../../components/add-edit-product-model/addEditProductModel"
import Confirmation from "../../../../../components/confirmation/confirmation"

export default function ProductModel() {
  const nav = useNavigate()
  const dispatch = useDispatch()
  const productModelData = useSelector(getproductModelStateSelector())
  const [selectedProductLine, setSelectedProductLine] = useState()
  const [modelsList, setModelsList] = useState([])
  const [showCreateNewRiskAssessment, setShowCreateNewRiskAssessment] =
    useState(false)
  const [showAddModel, setShowAddModel] = useState(false)

  const userRoleId = useSelector(getLoginStateSelector())?.loggedInUser?.role

  const [showAddProductModel, setShowAddProductModel] = useState(false)
  const [showEditProductModel, setShowEditProductModel] = useState(false)

  const [selectedModel, setSelectedModel] = useState()
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false)

  useEffect(() => {
    const selectedProductLine = JSON.parse(
      getItemFromSession(sessionAttributes.selectedProductLine)
    )
    setSelectedProductLine(selectedProductLine)
    getModels(selectedProductLine)
    return () => {
      dispatch(clearProductModelsData())
    }
  }, [])

  const getModels = selectedProductLine => {
    const payload = {
      productLineId: selectedProductLine.id,
      config: {}
    }
    dispatch(getProductModels(payload))
  }

  useEffect(() => {
    setModelsList(productModelData[0]?.machine)
  }, [productModelData])

  useEffect(() => {}, [modelsList])

  const handleRiskAssessmentAction = payload => {
    dispatch(
      createRiskAssessment({
        payload,
        callback: resp => {
          setShowCreateNewRiskAssessment(false)
        }
      })
    )
  }

  const handleViewRiskAssessments = () => {
    nav(allRoutes.dashboardRoutes.assessmentRoutes.riskAssessments, {
      state: {
        selectedProductLine,
        selectedRiskAssessment: "all",
        selectedModelId: null,
        raCount: productModelData[0]?.total_risk_assessment_count
      }
    })
  }
  const handleViewModalRiskAssessments = model => {
    nav(allRoutes.dashboardRoutes.assessmentRoutes.riskAssessments, {
      state: {
        selectedProductLine,
        selectedRiskAssessment: "all",
        selectedModelId: model.id,
        selectedModel:model,
        raCount: model.risk_assessments
      }
    })
  }
  const handleAddProductModelConfirm = payload => {
    dispatch(
      createProductModel({
        payload,
        callback: resp => {
          setShowAddProductModel(false)
          getModels(selectedProductLine)
        }
      })
    )
  }

  const handleEditProductModelConfirm = payload => {
    const id = selectedModel?.id
    dispatch(
      editProductModel({
        id,
        payload,
        callback: resp => {
          setShowEditProductModel(false)
          getModels(selectedProductLine)
        }
      })
    )
  }
  const handEditModel = data => {
    setSelectedModel(data)
    setShowEditProductModel(true)
  }

  const handDeleteModel = data => {
    setSelectedModel(data)
    setShowConfirmationPopUp(true)
  }
  const handleModelDeleteConfirm = () => {
    const id = selectedModel?.id
    setShowConfirmationPopUp(false)
    dispatch(
      deleteProductModel({
        id,
        callback: resp => {
          getModels(selectedProductLine)
        }
      })
    )
  }

  return (
    <div className="product-models-wrapper">
      <header>
        <div className="product-model-header">
          <div className="product-model-heading">
            <img src={selectedProductLine?.image} />
            <span>{selectedProductLine?.name}</span>
          </div>
          <div className="product-modal-actions">
            <Button
              variant="secondary-button"
              clickHandler={handleViewRiskAssessments}
            >
              View all Assessments
            </Button>
            {/* {rbac.newRiskAssessment(userRoleId) && <Button variant='submit-button' clickHandler={() => setShowCreateNewRiskAssessment(true)}>New Risk Assessment</Button>} */}

            {rbac.addnewMachine(userRoleId) && (
              <Button
                variant="submit-button"
                clickHandler={() => setShowAddProductModel(true)}
              >
                Add New Model
              </Button>
            )}
          </div>
        </div>
      </header>
      <main>
        <div className="product-model-list-wrapper">
          {modelsList?.map(model => (
            <ProductModelCard
              key={model.id}
              machineData={model}
              clickHandler={handleViewModalRiskAssessments}
              hasWritePrivileges={rbac.addnewMachine(userRoleId)}
              handleEdit={() => {
                handEditModel(model)
              }}
              handleDelete={() => {
                handDeleteModel(model)
              }}
            />
          ))}
        </div>
      </main>
      <NewRiskAsessment
        label={"New Risk Assessment"}
        show={showCreateNewRiskAssessment}
        setShow={setShowCreateNewRiskAssessment}
        handleRiskAssessmentAction={handleRiskAssessmentAction}
      />
      {showAddProductModel && (
        <AddEditProductModel
          label="Add New Model"
          show={showAddProductModel}
          setShow={setShowAddProductModel}
          initialFormData={{
            productLineName: selectedProductLine.name,
            productLineId: selectedProductLine.id
          }}
          onclickConfirm={handleAddProductModelConfirm}
        />
      )}
      {showEditProductModel && (
        <AddEditProductModel
          label="Edit Model"
          show={showEditProductModel}
          setShow={setShowEditProductModel}
          initialFormData={{
            productLineName: selectedProductLine.name,
            productLineId: selectedProductLine.id,
            productModelName: selectedModel?.name
          }}
          productImg={selectedModel?.image}
          onclickConfirm={handleEditProductModelConfirm}
          action={"edit"}
        />
      )}
      {showConfirmationPopUp && (
        <Confirmation
          label={"Delete Model"}
          show={showConfirmationPopUp}
          setShow={setShowConfirmationPopUp}
          confirmationType={"typeDelete"}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonClickHandler={handleModelDeleteConfirm}
          secondaryButtonClickHandler={() => setShowConfirmationPopUp(false)}
        />
      )}
    </div>
  )
}
