import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getPrivacyListSelector,
  getTermsListSelector
} from "../../redux/selectors/termsConditionsSelector"
import {
  getPrivacyPolicy,
  getTermsAndConditions
} from "../../redux/termsConditions/termsConditionsSlice"

import { assets } from "../../utils/assetsSrc"

import "./termsConditions.scss"

export default function TermsConditions({ policyType, label }) {
  const termsList = useSelector(getTermsListSelector())
  const privacyList = useSelector(getPrivacyListSelector())

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTermsAndConditions())
    dispatch(getPrivacyPolicy())
  }, [])

  return (
    <div className="policy-container">
      <div className="page-header">
        <div className="breadcrumb">{`Home > ${label}`}</div>
        <div className="heading">
          <img src={assets.Tnc} />
          <span>{label}</span>
        </div>
      </div>
      <div className="page-content mt-5">
        {policyType === "terms" ? (
          <div className="page-body"> {termsList?.[0]?.body} </div>
        ) : (
          <div className="page-body"> {privacyList?.[0]?.body} </div>
        )}
      </div>
    </div>
  )
}
