import React, { useEffect, useRef } from "react"
import { useTable, useExpanded, useSortBy } from "react-table"
import { Col } from "react-bootstrap"
// import { assets } from "../../utils"
// import Loader from "../loader/loader"
import "./table.scss"
import { assets } from "../../utils/assetsSrc"

function CustomeTable({
  columns: userColumns,
  data,
  renderRowSubComponent,
  isLoading,
  rowsExpanded,
  height,
  initialSortKey,
  myClassName = "",
  id = "",
  isAsc,
  disabledSort,
  onRowClick = () => {},
  filters
}) {
  let scrollParentRef
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    customClass
  } = useTable(
    {
      columns: userColumns,
      initialState: {
        sortBy: [
          {
            id: initialSortKey || "",
            desc: isAsc || false
          }
        ]
      },
      data: data || [],
      disableSortBy: disabledSort ?? true
    },
    useSortBy
  )

  const Table = () => {
    return (
      <>
        <table id={id} className={myClassName} {...getTableProps()}>
          <thead style={{ zIndex: "1" }}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => column?.toggleSortBy?.(!column.isSortedDesc)}
                  >
                    {column.render("Header")}
                    {column.canSort ? (
                      <img
                        style={{ position: "absolute", marginLeft: 10 }}
                        src={assets.sortIcon}
                      />
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {filters && (
            <React.Fragment>
              <tr className="table-filter">
                {filters?.map(cell => {
                  return <td>{cell?.render}</td>
                })}
              </tr>
            </React.Fragment>
          )}
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 &&
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <React.Fragment key={i}>
                    <tr
                      className="tr-main"
                      {...row.getRowProps()}
                      onClick={() => onRowClick(row.original)}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ maxWidth: cell.column.width }}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                )
              })}
          </tbody>
        </table>
        {!isLoading && !rows.length && (
          <Col className="text-center">
            <p
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                color: "#fff"
              }}
            >
              No results found
            </p>
          </Col>
        )}
        {isLoading && (
          <Col className="text-center">
            {/* <Loader style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%"
                            }} /> */}
          </Col>
        )}
      </>
    )
  }

  return (
    <div
      style={{ height }}
      className={`${customClass} table-wrapper`}
      ref={node => {
        scrollParentRef = node
      }}
    >
      <Table />
    </div>
  )
}

export default React.memo(CustomeTable)
