import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {assets} from '../../utils/assetsSrc';
import './confirmation.scss';
import Button from '../form/button/button';

export default function Confirmation({
  label,
  show,
  setShow,
  confirmationType = 'normal',
  confirmationText,
  secondaryButtonText = 'Cancel',
  primaryButtonText = 'Confirm',
  primaryButtonClickHandler,
  secondaryButtonClickHandler,
}) {
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const handleChange = e => {
    setDeleteText(e.target.value.toUpperCase());
  };
  useEffect(() => {
    if (deleteText === 'DELETE') {
      setDeleteConfirmed(true);
    } else {
      setDeleteConfirmed(false);
    }
  }, [deleteText]);
  return (
    <Modal
      show={show}
      onHide={() => {
        setDeleteText('');
        setShow(false);
      }}
      aria-labelledby="confirm-cancel"
      centered
      className="confirm-cancel-box">
      <Modal.Body className="p-3 px-3 text-center">
        {confirmationType === 'typeDelete' && (
          <div className="close-icon">
            <img
              onClick={() => {
                setDeleteText('');
                setShow(false);
              }}
              src={assets.closeIcon}
            />
          </div>
        )}
        <div className="main-content">
          <div className="header">
            <p className="label mt-3 mb-4">{label}</p>
          </div>
          {confirmationType === 'typeDelete' ? (
            <div className="type-delete-wrapper">
              <div className="help-text">Please type DELETE to confirm?</div>
              <input
                type="text"
                value={deleteText}
                onChange={handleChange}
                className=""
                placeholder="DELETE"
              />
            </div>
          ) : (
            <div>{confirmationText}</div>
          )}
          <div className="action-btns">
           {confirmationType === 'typeDelete' && <Button
              variant={'secondary-button'}
              clickHandler={() => {
                setDeleteText('');
                secondaryButtonClickHandler();
              }}>
              {secondaryButtonText}
            </Button>}
            <Button
              disabled={confirmationType === 'typeDelete' && !deleteConfirmed}
              variant={'submit-button'}
              clickHandler={primaryButtonClickHandler}>
              {primaryButtonText}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
