import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';


const signupState = (state) => {
    return state.persistedReducer.signupReducer;
}

// same input and output state to maintain architecture
// export const loginState = (appState) => createSelector(appState,(state)=>{
//     return state
// })

export const getSignupStateSelector = () => createSelector(signupState,(state)=>{
    return state
})