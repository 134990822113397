import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  createRiskAssessment,
  deleteRiskAssessment,
  editRiskAssessment,
  getAllRiskAssessments,
  getRiskAssessments,
} from '../../../../../redux/risk-assessment/riskAssessmentSlice';
import {useSelector} from 'react-redux';
import {
  getRiskAssessmentLoadingState,
  getRiskAssessmentStateSelector,
} from '../../../../../redux/selectors/riskAssessment';
import Button from '../../../../../components/form/button/button';
import NewRiskAsessment from '../../components/new-risk-assessment/newRiskAsessment';
import RiskAssessmentCard from '../../components/risk-assessment-card.js/risk-assessment-card';
import {allRoutes} from '../../../../../utils/routeConstants';
import {getLoginStateSelector} from '../../../../../redux/selectors/loginSelector';
import {
  rbac,
  riskAssessmentAccessIds,
  riskAssessmentAccessNames,
  taskStatusIDS,
} from '../../../../../utils/constants';
import Confirmation from '../../../../../components/confirmation/confirmation';
import './riskAssessment.scss';
import Loader from '../../../../../components/loader/loader';
import CustomSearch from '../../../../../components/custom-search/customSearch';

export default function RiskAssessment() {
  const [riskAssessmentModels, setRiskAssessmentModels] = useState([]);
  const [heading, setHeading] = useState('');
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [showCreateNewRiskAssessment, setShowCreateNewRiskAssessment] =
    useState(false);
  const [showDuplicateRiskAssessment, setShowDuplicateRiskAssessment] =
    useState(false);
  const [showEditRiskAssessment, setShowEditRiskAssessment] = useState(false);
  const [clickedRiskAssessment, setClickedRiskAssessment] = useState({});
  const [selectedRiskAssessmentDetails, setSelectedRiskAssessmentDetails] =
    useState({});
  const [riskAssessmentConfig, setRiskAssessmentConfig] = useState({
    params: {search: ''},
  });
  const isLoading = useSelector(getRiskAssessmentLoadingState());
  const location = useLocation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  let searchTimer;
  const riskAssessmentState = useSelector(getRiskAssessmentStateSelector());
  const userRoleId = useSelector(getLoginStateSelector())?.loggedInUser?.role;
  const modelId = location.state?.selectedModelId;

  useEffect(() => {
    // All risk Assessments for a product line
    const productLineId = location.state?.selectedProductLine?.id;
    const modelId = location.state?.selectedModelId;
    if (modelId) {
      fetchRiskAssessments({modelId, riskAssessmentConfig});
    } else if (productLineId) {
      fetchRiskAssessments({productLineId, riskAssessmentConfig});
    }
    fetchProductLineAllRiskAssessments({productLineId, riskAssessmentConfig});
  }, [location, riskAssessmentConfig]);

  useEffect(() => {
    const modelList = riskAssessmentState
      .map(item => item.machine_detail)
      .flat(1);
    const uniqueModelList = [
      ...new Map(modelList.map(item => [item['name'], item])).values(),
    ];
    if (modelId)
      setRiskAssessmentModels(
        uniqueModelList.filter(item => item.id == modelId),
      );
    else setRiskAssessmentModels(uniqueModelList);
    let totalTasks = 0;
    let completedTasks = 0;
    riskAssessmentState.forEach(ra => {
      totalTasks += ra.task_detail?.length;
      completedTasks +=
        ra.task_detail?.filter(
          item => item.task_status === taskStatusIDS.completed,
        )?.length ?? 0;
    });
    setHeading(
      `Progress: ${completedTasks}/${totalTasks} task assessed from ${riskAssessmentState.length} assessments`,
    );
  }, [riskAssessmentState]);

  const handleRiskAssessmentAction = payload => {
    dispatch(
      createRiskAssessment({
        payload,
        callback: resp => {
          fetchRiskAssessments({
            productLineId: location.state?.selectedProductLine?.id,
            modelId: location.state?.selectedModelId,
          });
          fetchProductLineAllRiskAssessments({
            productLineId: location.state?.selectedProductLine?.id,
          });
          setShowCreateNewRiskAssessment(false);
        },
      }),
    );
  };
  const handleEditRiskAssessmentAction = payload => {
    const id = clickedRiskAssessment?.id;
    dispatch(
      editRiskAssessment({
        id,
        payload,
        callback: resp => {
          fetchRiskAssessments({
            productLineId: location.state?.selectedProductLine?.id,
            modelId: location.state?.selectedModelId,
          });
          fetchProductLineAllRiskAssessments({
            productLineId: location.state?.selectedProductLine?.id,
          });

          setShowEditRiskAssessment(false);
        },
      }),
    );
  };

  const fetchRiskAssessments = ({productLineId, modelId}) => {
    if (modelId) {
      dispatch(
        getRiskAssessments({modelId: modelId, config: riskAssessmentConfig}),
      );
    } else if (productLineId) {
      dispatch(
        getRiskAssessments({
          productLineId: productLineId,
          config: riskAssessmentConfig,
        }),
      );
    }
  };

  const fetchProductLineAllRiskAssessments = ({productLineId}) => {
    dispatch(
      getAllRiskAssessments({
        productLineId: productLineId,
        config: riskAssessmentConfig,
      }),
    );
  };

  const handleRiskAssesmentSelection = selected => {
    nav(
      `${allRoutes.dashboardRoutes.assessmentRoutes.specificRiskAssessment}`,
      {
        state: {
          ...location.state,
          selectedRiskAssessment: selected,
        },
      },
    );
  };
  const handleRaDuplicate = data => {
    const obj = {
      data: {
        riskAssessmentName: data.name,
        accessType: riskAssessmentAccessNames[data.access],
        userAccessList: data.user_detail,
        productLine: data.product_detail,
        modelsList: data.machine_detail,
      },
      access: data.access,
    };
    setSelectedRiskAssessmentDetails(obj);
  };
  const handleRaDelete = () => {
    const id = clickedRiskAssessment?.id;
    setShowConfirmationPopUp(false);
    dispatch(
      deleteRiskAssessment({
        id,
        callback: () => {
          fetchRiskAssessments({
            productLineId: location.state?.selectedProductLine?.id,
            modelId: location.state?.selectedModelId,
          });
        },
      }),
    );
  };
  const handleuserEditAccess = id => {};
  const handleRaEdit = id => {};

  const handleInputChange = e => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      const obj = {...riskAssessmentConfig};
      obj.params.search = e.target.value;
      setRiskAssessmentConfig(obj);
    }, 1000);
  };

  return (
    <div className="risk-assessment-list-wrapper">
      <header>
        <div className="risk-assessment-header">
          <div className="risk-assessment-info">
            <span className="heading">{heading}</span>
            <span className="model-list">
              {riskAssessmentModels.map((model, idx) => (
                <span key={`${model.id}-${idx}`} className="model-chip">
                  {model.name}
                </span>
              ))}
            </span>
          </div>
          <div className="risk-assessment-actions">
            {rbac.newRiskAssessment(userRoleId) && (
              <Button
                clickHandler={() => setShowCreateNewRiskAssessment(true)}
                variant="submit-button">
                New Risk Assessment
              </Button>
            )}
          </div>
        </div>
        <div className="pl-search">
          <CustomSearch
            onChange={handleInputChange}
            width="400px"
            type="text"
          />
        </div>
      </header>

      <div className="risk-assessment-list">
        {riskAssessmentState.map(ra => (
          <RiskAssessmentCard
            key={ra.id}
            data={ra}
            handleRaDuplicate={() => {
              handleRaDuplicate(ra);
              setShowDuplicateRiskAssessment(true);
            }}
            handleuserEditAccess={() => handleuserEditAccess(ra)}
            handleRaDelete={() => {
              setShowConfirmationPopUp(true);
              setClickedRiskAssessment(ra);
            }}
            handleRaEdit={() => {
              handleRaDuplicate(ra);
              setShowEditRiskAssessment(true);
              setClickedRiskAssessment(ra);
            }}
            handleRiskAssesmentSelection={handleRiskAssesmentSelection}
            hasWritePrivileges={rbac.riskAssessmentMoreActions(userRoleId)}
          />
        ))}
      </div>
      {showCreateNewRiskAssessment && (
        <NewRiskAsessment
          label={'New Risk Assessment'}
          show={showCreateNewRiskAssessment}
          setShow={setShowCreateNewRiskAssessment}
          handleRiskAssessmentAction={handleRiskAssessmentAction}
        />
      )}
      {showDuplicateRiskAssessment && (
        <NewRiskAsessment
          label={'Duplicate Risk Assessment'}
          show={showDuplicateRiskAssessment}
          prefilledData={selectedRiskAssessmentDetails}
          setShow={setShowDuplicateRiskAssessment}
          handleRiskAssessmentAction={handleRiskAssessmentAction}
        />
      )}
      {showEditRiskAssessment && (
        <NewRiskAsessment
          label={'Edit Risk Assessment'}
          show={showEditRiskAssessment}
          prefilledData={selectedRiskAssessmentDetails}
          setShow={setShowEditRiskAssessment}
          action="edit"
          handleRiskAssessmentAction={handleEditRiskAssessmentAction}
        />
      )}
      {showConfirmationPopUp && (
        <Confirmation
          label={'Delete Risk Assessment'}
          show={showConfirmationPopUp}
          setShow={setShowConfirmationPopUp}
          confirmationType={'typeDelete'}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonClickHandler={handleRaDelete}
          secondaryButtonClickHandler={() => setShowConfirmationPopUp(false)}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
}
