import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { importEvent } from "../../../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice"
import { assets } from "../../../../../../../utils/assetsSrc"
import ImportEvent from "../../../../components/import-event/importEvent"


export default function SubCategoryEvents({
  subCat,
  idx,
  handleEventSelection,
  handldeSubCatDelete,
  addNoteToEventSubCat,
  readOnly,
}) {
  const [addSubNote, setAddSubNote] = useState(subCat?.note?.length > 0)
  const [subCatNote, setSubCatNote] = useState(subCat.note || "")
  const [showImportModal,setShowImportModal] = useState(false)
  const dispatch = useDispatch()
  const handleAddSubCatNote = () => {
    setAddSubNote(true)
  }

  const handleImportEventAction = payload => {
    dispatch(
      importEvent({
        payload,
        callback: resp => {
          setShowImportModal(false)
        }
      })
    )
  }

  return (
    <div className={`sub-category-list-wrapper`}>
      <div className="main-row">
        <div className="row-1">
          <div className="heading">
            <span className="number">{`${
              subCat?.event_detail?.event_sequence
            }.${idx + 1}`}</span>
            <div className="name-note-container">
              <span className="name">
                {subCat?.event_sub_category_detail?.name}
              </span>
              {subCat.note && <div className="note">{subCat.note}</div>}
            </div>
          </div>
        </div>
        <div className="more-details">
          {!readOnly && (
            <div className="copy-events"  onClick={() => setShowImportModal(true)}>
              <img style={{marginRight: '5px'}} src={assets.copyIcon} />
              Import
            </div>
          )}
          {!readOnly && (
            <div onClick={handleAddSubCatNote}>
              <img style={{marginRight: '5px'}} src={assets.addFilled} />
              <span className="add-note-text">
                {subCat.note ? 'Edit Note' : 'Add note'}
              </span>
            </div>
          )}
          {!readOnly && (
            <div style={{marginLeft: '15px'}}>
              <img
                onClick={() =>
                  handldeSubCatDelete(
                    subCat?.id,
                    subCat?.event_sub_category_detail?.id,
                  )
                }
                src={assets.trash}
              />
            </div>
          )}
          <div style={{marginLeft: '15px'}} className="forward-arrow">
            <span className="forward-arrow" onClick={handleEventSelection}>
              <img src={assets.chevronRight} />
            </span>
          </div>
        </div>
      </div>
      {addSubNote && !readOnly && (
        <div className="note-row">
          <label htmlFor="note-input">Note (optional)</label>
          <input
            id="note-input"
            value={subCatNote}
            onChange={e => setSubCatNote(e.target.value)}
            type="text"
          />

          <span style={{display: 'flex'}}>
            <img
              onClick={() => addNoteToEventSubCat(subCat?.id, subCatNote)}
              style={{scale: '1.2', margin: 10}}
              src={assets.checkIcon}
            />
            <img
              onClick={() => setAddSubNote(false)}
              style={{scale: '1.2', margin: 10}}
              src={assets.closeIcon}
            />
          </span>
        </div>
      )}
      { showImportModal && (
        <ImportEvent
          label={'Import'}
          show={showImportModal}
          setShow={setShowImportModal}
          data= {subCat}
          handleImportEventAction={handleImportEventAction}
        />
      )}
    </div>
  );
}
