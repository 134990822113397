import { call, put, take, takeLatest } from "redux-saga/effects"
import { apiFunctions } from "../../api/functions"
import { errorHandler } from "../../utils/errorHandling"
import {changePasswordSuccess, changePasswordFailure}  from "./passwordSlice"

//API

const changePasswordAPI = apiFunctions.changePassword

async function changePasswordHandlerAPI(payload){
    return await changePasswordAPI(payload)
}

function *changePasswordHandler({payload}){
    try{
        const result = yield call(changePasswordHandlerAPI, payload)
        yield put(changePasswordSuccess(result.data))
    }
    catch (error){
        const err = errorHandler(error)
        yield put(changePasswordFailure(err))
    }
}

export function* passwordWatchers() {
    yield takeLatest("password/changePassword", changePasswordHandler)
}