import React from 'react'
import { assets } from '../../../../../utils/assetsSrc'
import "./summary.scss"

export default function CustomSearch({ onChange,value }) {
  return (
    <div className='task-search-wrapper'>
      <input type='text' onChange={onChange} placeholder='Search'  defaultValue={value || ''}/>
      <img className='search-img' src={assets.search} />
    </div>
  )
}
