import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
    signUpResponse:{}
}

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        initSignup: (state, action) => {
            // some state mutation can be performed
        },
        signupSuccess: (state, action) => {
            state.signUpResponse = action.payload.response
            toast.success('Signed up successfully',{autoClose:2000, className:'toast-success'})
        },
        signupFailure: (state, action) => {
            state.signUpResponse = action.payload.response
            toast.error(action.payload.response.message,{className:'toast-fail'})
        },
        clearSignupState: (state) => {
            state.signUpResponse = {}
        }
    }
})

export default signupSlice.reducer;
export const { initSignup, signupSuccess, signupFailure, clearSignupState } = signupSlice.actions;