import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {assets} from '../../../../../utils/assetsSrc';
import './risk-assessment-card.scss';
import {
  riskAssessmentAccessNames,
  taskStatusIDS,
} from '../../../../../utils/constants';
import {changeDateFormat} from '../../../../../utils/DateUtils';

export default function RiskAssessmentCard({
  data,
  handleRiskAssesmentSelection,
  handleRaDuplicate,
  handleuserEditAccess,
  handleRaDelete,
  handleRaEdit,
  hasWritePrivileges,
  isDeleted,
  handleRestore,
  handlePermanentDelete,
}) {
  const [raModelList, setRaModelList] = useState([]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  useEffect(() => {
    const modelList = data.machine_detail
      ?.map(machine => machine.name)
      .join(', ');
    setRaModelList(modelList);
  }, [data]);

  const completedTaskCount = data?.task_detail?.filter(
    item => item.task_status === taskStatusIDS.completed,
  )?.length;

  const isAllTaskCompleted =
    completedTaskCount === data?.task_detail?.length &&
    data?.task_detail?.length !== 0;

  return (
    <div
      className="risk-assessment-card-wrapper"
      onClick={() => handleRiskAssesmentSelection?.(data)}>
      <div style={{width: '100%'}}>
        <Row>
          <Col xs={6} className="ra-name">
            {data?.name}
          </Col>
          <Col className="action-btns" xs={6}>
            {showMoreOptions && (
              <span className="more-options">
                <span
                  className="duplicate"
                  onClick={e => {
                    e.stopPropagation();
                    handleRaDuplicate();
                  }}>
                  <img src={assets.copyIcon} />
                  Duplicate
                </span>
                {/* <span className='access' onClick={(e) => { e.stopPropagation(); handleuserEditAccess() }}>User/Edit Access</span> */}
                <span
                  className="delete"
                  onClick={e => {
                    e.stopPropagation();
                    handleRaDelete();
                  }}>
                  <img src={assets.trash} />
                </span>
                <span
                  className="edit"
                  onClick={e => {
                    e.stopPropagation();
                    handleRaEdit();
                  }}>
                  <img src={assets.editIcon2} />
                </span>
              </span>
            )}
            {hasWritePrivileges && (
              <img
                style={{padding: '10px'}}
                src={assets.moreIcon}
                onClick={e => {
                  e.stopPropagation();
                  setShowMoreOptions(!showMoreOptions);
                }}
              />
            )}
            {/* <img src={data.access === 1 ? assets.lockFilled : assets.unlockIcon} /> */}
            {/* {!isDeleted && (
              <img className="excel-icon" src={assets.excelIcon} />
            )} */}
            {isDeleted && (
              <div className="restore-wrapper">
                <span className="restore-text" onClick={handleRestore}>
                  Restore
                </span>
                <span
                  className="delete"
                  onClick={e => {
                    e.stopPropagation();
                    handlePermanentDelete();
                  }}>
                  <img src={assets.trash} />
                </span>
                <div className="date-text">
                  {`You can Restore before ${changeDateFormat(
                    data.restore_before_date,
                    'YYYY-MM-DD',
                    'DD MMM YY',
                  )}`}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="product-line">Product-line: </span>
            <span className="product-line-name">
              {data?.product_detail?.name}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
            <span className="product-model">Model: </span>
            <span className="product-model-name">{raModelList}</span>
          </Col>
          <Col xs={5} style={{textAlign: 'right'}}>
            <span
              className={`${
                riskAssessmentAccessNames[data.access]
              } access-type`}>
              {riskAssessmentAccessNames[data.access]}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="task-status-wrapper">
            <span
              className={`${
                isAllTaskCompleted ? 'task-completed' : 'task-progress'
              } task-status`}>
              {data?.task_detail?.length === 0
                ? 'Not started: '
                : isAllTaskCompleted
                ? 'Completed: '
                : 'Progress: '}
              {completedTaskCount}/{data?.task_detail?.length} task assessed
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}
