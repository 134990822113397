import React from 'react'
import { Outlet } from 'react-router-dom';

export default function UserListMain() {
    return (
        <Outlet>

        </Outlet>
    )
}
