import React, { useState } from "react"
import { ErrorMessage, useField } from "formik"
import { assets } from "../../../utils/assetsSrc"

import "./textfield.scss"

const TextField = ({
  type,
  label,
  prefixIcon,
  suffixIcon,
  styles,
  isMasked,
  iconClick = () => {},
  customClass,
  rightElement,
  rightElementStyles,
  isMultiline,
  textAreaStyle,
  ...props
}) => {
  const [field, meta] = useField(props)
  const [passwordState, setPasswordState] = useState(type)
  const sufIcon = () => {
    if (suffixIcon === "email") {
      return assets.EmailAt
    } else if (suffixIcon === "lock") {
      return assets.lock
    } else if (suffixIcon === "closeEye") {
      return passwordState === "password" ? assets.closeEye : assets.openEye
    }
  }

  const handleIconCick = () => {
    if (suffixIcon === "closeEye" || suffixIcon === "openEye") {
      if (passwordState === "password") {
        suffixIcon = "openEye"
        setPasswordState("text")
      } else {
        suffixIcon = "closeEye"
        setPasswordState("password")
      }
    }
    return
  }
  return (
    <div className="mb-4" style={{ position: "relative" }}>
      <div className="field-container" style={styles}>
        {label ? <label htmlFor={field.name}>{label}</label> : <></>}
        {isMultiline ? (
          <textarea
            type={passwordState}
            className={`form-control shadow-none ${customClass} ${
              meta.touched && meta.error && "is-invalid"
            }`}
            {...field}
            {...props}
            autoComplete="off"
            style={textAreaStyle}
          />
        ) : (
          <input
            type={passwordState}
            className={`form-control shadow-none ${customClass} ${
              meta.touched && meta.error && "is-invalid"
            }`}
            {...field}
            {...props}
            autoComplete="off"
          />
        )}
        {suffixIcon ? (
          <img
            src={sufIcon()}
            className={`suffix-icon ${meta.error ? "d-none" : ""}`}
            onClick={handleIconCick}
          />
        ) : (
          <></>
        )}
        <div style={rightElementStyles}>{rightElement}</div>
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className="error"
        style={{ position: "absolute" }}
      />
    </div>
  )
}

export default TextField
