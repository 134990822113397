import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { getLoginStateSelector } from '../../redux/selectors/loginSelector';
import { passwordResetEmailInit, passwordResetVerifyCodeInit, passwordResetPasswordChangeInit,
  clearPasswordResetState } from '../../redux/login/loginSlice';

import { assets } from '../../utils/assetsSrc';
import constants from '../../constants/loginSignup.constants';

import SplashWrapper from '../../components/splash/splash-wrapper';
import TextField from '../../components/form/textfield/textfield';
import Button from '../../components/form/button/button';

import './container.scss';
import { useSelector } from 'react-redux';



export default function ForgotPasswordContainer() {
  const [step, setStep] = useState(1);
  const loginState = useSelector(getLoginStateSelector());
  const emailRef = useRef();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { passwordReset } = loginState;
  const { email, verificationCode, passwordChange } = passwordReset

  const handleEmailSubmit = (values) => {
    emailRef.current = values.email;
    const payload = { email: values.email }
    dispatch(passwordResetEmailInit(payload))
    // setStep(2)
  }
  const handleVerifyCodeSubmit = (values) => {
    const payload = { email: emailRef.current, code: values.code }
    dispatch(passwordResetVerifyCodeInit(payload))
    // setStep(3)
  }
  const newPasswordSubmit = (values) => {
    const payload = {
      email: emailRef.current,
      new_password: values.password,
      confirm_password: values.password //remove this field
    }
    dispatch(passwordResetPasswordChangeInit(payload))
    // setStep(4)
  }

  const validateEmail = Yup.object({
    email: Yup.string().email("Email is invalid!").required("Email Required!"),
  });
  const validateVerificationCode = Yup.object({
    code: Yup.string().required("Verification code Required!"),
  });
  const validateNewPassword = Yup.object({
    password: Yup.string().required("Password Required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required("Confrm Password Required!"),
  });
  const step1Configs = {
    step: 1,
    heading: 'Forgot Password',
    initialValues: { email: '' },
    quote: constants.emailQuote,
    validationSchema: validateEmail,
    submitButtonText: 'Send verification code',
    fields: <><TextField
      type="text"
      label="Email ID"
      name="email"
      placeholder="Enter email"
      customClass="email"
      suffixIcon="email"
    /></>,
  }
  const step2Configs = {
    step: 2,
    heading: 'Verification',
    initialValues: { code: '' },
    quote: constants.verificationText,
    validationSchema: validateVerificationCode,
    submitButtonText: 'Verify',
    fields: <><TextField
      type="password"
      label="verification code"
      name="code"
      placeholder=""
      isMasked={true}
      customClass="verificationCode"
    /></>,
  }
  const step3Configs = {
    step: 3,
    heading: 'Reset Password',
    initialValues: { password: '', confirmPassword: '' },
    quote: constants.changePassword,
    validationSchema: validateNewPassword,
    submitButtonText: 'Update Password',
    fields: <><TextField
      type="password"
      label="New Password*"
      name="password"
      placeholder=""
      isMasked={true}
      customClass=""
      suffixIcon="closeEye"
    />
      <TextField
        type="password"
        label="Confirm New Password*"
        name="confirmPassword"
        placeholder=""
        isMasked={true}
        customClass=""
        suffixIcon="closeEye"
      />
    </>,
  }
  const step4Configs = {
    step: 4,
    heading: 'Password Changed!',
    submitButtonText: 'Login',
  }
  const FormWrapper = ({ configs }) => {
    return (
      <div className='forgot-password'>
        <div className='heading'>
          {configs.heading}
        </div>
        <div className='quote'>
          {configs.quote}
        </div>
        {step !== 4 && <Formik
          initialValues={configs.initialValues}
          validationSchema={configs.validationSchema}
          onSubmit={configs.step === 1 ? handleEmailSubmit :
            (configs.step === 2 ? handleVerifyCodeSubmit : newPasswordSubmit)
          }
        >
          {(formik) => (
            <Form>
              <div>
                {configs.fields}
                <div className='submit-button'>
                  <Button
                    type="submit"
                    variant='submit-button'
                  // clickHandler={() => handleEmailSubmit()}
                  >{configs.submitButtonText}</Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>}
        {configs.step === 2 && <div className='verification-link-resend'>
          <span>didn't receive yet?&nbsp;</span><span onClick={() => dispatch(passwordResetEmailInit({ email: emailRef.current }))} className='link-resend'>Resend</span>
        </div>}
        {step === 4 && <div>
          <img src={assets.successIcon} />
          <div className='success-icon'>{constants.changePasswordSuccess}</div>
          <Button
            type="submit"
            variant='submit-button'
            clickHandler={() => nav('/login')}
          >{configs.submitButtonText}</Button>
        </div>}
      </div>
    )
  }

  useEffect(() => {
    if ( emailRef.current && email.success.length && email.type === 'success') {
      setStep(2)
    }
  }, [email]);

  useEffect(() => {
    if (emailRef.current && verificationCode.success.length && verificationCode.type === 'success') {
      setStep(3)
    }
  }, [verificationCode]);

  useEffect(() => {
    if (emailRef.current && passwordChange.success.length && passwordChange.type === 'success') {
      setStep(4)
      dispatch(clearPasswordResetState())
    }
  }, [passwordChange]);


  return (
    <>
      <SplashWrapper>
        <div className='wrapper'>
          <img className='logo' src={assets.komatsuLogo} />
          {step === 1 && <FormWrapper configs={step1Configs} />}
          {step === 2 && <FormWrapper configs={step2Configs} />}
          {step === 3 && <FormWrapper configs={step3Configs} />}
          {step === 4 && <FormWrapper configs={step4Configs} />}
        </div>
      </SplashWrapper>
    </>
  )
}
