import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { assets } from '../../../utils/assetsSrc'

export default function ProductLineAccess() {
  const location = useLocation()
  const nav = useNavigate()

  return (
    <>
      <header>
        <div>
        <span onClick={()=>nav(-1)}>
          <img src={assets.backArrow} />
          <span>Back</span>
        </span>
        <span>
          ZARA Brooks
        </span>
        </div>
      </header>
    </>
  )
}
