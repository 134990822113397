import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading : false,
    termsList : [],
    privacyList: []
}

const termsConditionsSlice = createSlice({
    name: "termsConditions",
    initialState,
    reducers: {

        //get terms and conditions
        getTermsAndConditions: state => {
            state.isLoading = true
        },

        getTermsAndConditionsSuccess: (state,action) => {
            const response = action.payload
            state.isLoading = false
            state.termsList = response
        },

        getTermsAndConditionsFailure: state => {
            state.isLoading = false
        },

        //get privacy policy
        getPrivacyPolicy: state => {
            state.isLoading = true
        },

        getPrivacyPolicySuccess: (state,action) => {
            const response = action.payload
            state.isLoading = false
            state.privacyList = response
        },

        getPrivacyPolicyFailure: state => {
            state.isLoading = false
        }
    }
})

export default termsConditionsSlice.reducer

export const {
    getTermsAndConditions,
    getTermsAndConditionsSuccess,
    getTermsAndConditionsFailure,
    getPrivacyPolicy,
    getPrivacyPolicySuccess,
    getPrivacyPolicyFailure
} = termsConditionsSlice.actions