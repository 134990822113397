import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { assets } from "../../../../../utils/assetsSrc";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SingleSelectCheckmarks({data, handleOrderingChange}){

    const [personName, setPersonName] = useState("")

    useEffect(() => {
        if(personName){
            handleOrderingChange(personName)
        }
    }, [personName])

    const handleChange = event => {
        setPersonName(event.target.value)
    }


    return (
        <div>
            <FormControl sx={{m: 1, width: 200, background: 'white'}}>
                {/* <InputLabel > <img src={assets.filterIcon}/></InputLabel> */}
                <Select
                    value= {personName}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="" disabled>
                        <img src={assets.filterIcon}/>
                        <em> Sort by</em>
                    </MenuItem>
                    {data.map(item => (
                        <MenuItem
                            key={item.id}
                            value={item}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}