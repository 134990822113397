import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { useFetcher, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import SplashWrapper from '../../components/splash/splash-wrapper';
import TermsConditions from '../termsConditions.js/termsConditions';

import { assets } from '../../utils/assetsSrc';

import './onboardingSlides.scss';
import { getLoginStateSelector } from '../../redux/selectors/loginSelector';
import { useSelector } from 'react-redux';

export default function OnboardingSlides() {
  const loginState = useSelector(getLoginStateSelector());
  const [currentContent, setCurrentContent] = useState('home');
  const emailRef = useRef();
  const nav = useNavigate()

  const location = useLocation();

  const { isLoggedIn } = loginState;

  // check for LoggedIn State and navigate
  useEffect(() => {
    if (isLoggedIn) {
      nav('/dashboard')
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (location.pathname.includes('termsAndConditions')) {
      setCurrentContent('TnC')
    }
    if (location.pathname.includes('privacyPolicy')) {
      setCurrentContent('privacyPolicy')
    }
    if (location.pathname.includes('contact')) {
      setCurrentContent('contact')
    }
  }, [location])

  const handleGetStarted = () => {
    sessionStorage.setItem('getStartEmail',emailRef.current.value)
    nav('/signup')
  }
  
  const MyCarousal = () => {
    return (
      <Carousel>
        <Carousel.Item>
          <div className='carousal-content'>
            <div className='left-content'>
              {/* <img src={assets.carouselMock} /> */}
            </div>
            <div className='right-content'>
              <div className='heading'>Komatsu Risk Assessment Application</div>
              <div className='description'>
              Welcome to the Komatsu application for performing task-based risk assessments.  This application was developed to support a common approach for achieving safety in the design of Komatsu equipment.  It considers processes outlined by ISO 12100:2010 and EMESRT Operability and Maintainability Analysis Technique (OMAT).  The outcome from risk assessments performed using this process include hazard identification and hazard mitigation for tasks on Komatsu equipment.
              </div>
              <div className='input-fields'>
                <span className='input'>
                  <input ref={emailRef} type='text' placeholder='Email ID' />
                </span>
                <span className='button'>
                  <button onClick={handleGetStarted}>Get Started</button>
                </span>
              </div>
              <div className='existing-user-text'>
                <span className='first'>Already have an Account? &nbsp;</span>
                <span onClick={() => nav('/login')} className='second'>Login</span>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    )
  }

  return (
    <SplashWrapper gradient="100">
      <div className='onboarding-container'>
        <Header />
        {currentContent === 'home' && <div className='myCarousal'><MyCarousal /></div>}
        {currentContent === 'contact' && <div></div>}
        {currentContent === 'TnC' && <TermsConditions policyType='terms' label='Terms of Use and Privacy' />}
        {currentContent === 'privacyPolicy' && <TermsConditions policyType='privacy' label='Privacy Policy' />}
      </div>
      <Footer />
    </SplashWrapper>
  )
}
