import RadioButtonWithLabel from './readio-button-with-label';
import React, {useState} from 'react';
import './event-details.scss';
import AddNote from '../../components/add-note/add-note';
const ControlSystem = ({setFieldValue, values, showInfoIcon, readOnly}) => {
  const [showNoteInput, setShowNoteInput] = useState(
    values?.safety_note?.length > 0,
  );

  return (
    <div>
      <RadioButtonWithLabel
        label={'Control system implemented to mitigate a hazard?'}
        name={'cntrl_hazard'}
        setFieldValue={setFieldValue}
        value={values.cntrl_hazard}
        showInfoIcon={showInfoIcon}
        disabled={readOnly}
      />
      <RadioButtonWithLabel
        label={'Control system controlling machine motion?'}
        name={'cntrl_motion'}
        setFieldValue={setFieldValue}
        value={values.cntrl_motion}
        showInfoIcon={showInfoIcon}
        disabled={readOnly}
      />

      <div className="heading">
        {values['cntrl_hazard'] == 1 || values['cntrl_motion'] == 1
          ? 'Functional safety required'
          : 'Not a control system that controls machine motion or a control system to mitigate a hazard'}
      </div>
      <AddNote
        showNoteInput={showNoteInput}
        setShowNoteInput={setShowNoteInput}
        name={'safety_note'}
        value={values.safety_note}
        setFiledValue={setFieldValue}
        readOnly={readOnly}
      />
      <hr />
    </div>
  );
};

export default ControlSystem;
