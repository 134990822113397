import React from "react"
import "./event-details.scss"
import { Checkbox } from "@mui/material"

const CheckBoxWithLabel = ({ label, value, onChange, disabled }) => {
  return (
    <div className="action-check-box">
      {label}
      <Checkbox
        checked={value}
        onChange={e => {
          onChange(e)
        }}
        inputProps={{ "aria-label": "controlled" }}
        disabled={disabled}
      />
    </div>
  )
}

export default CheckBoxWithLabel
