import RadioButtonWithLabel from './readio-button-with-label';
import React, {useState} from 'react';
import './event-details.scss';
import AddNote from '../../components/add-note/add-note';

const RiskLowered = ({
  setFieldValue,
  values,
  shoInfoIcon,
  eventName,
  taskName,
  readOnly
}) => {
  const [showNoteInput, setShowNoteInput] = useState(
    values?.risk_low_accpt_level_note?.length > 0,
  );

  return (
    <div>
      <RadioButtonWithLabel
        setFieldValue={setFieldValue}
        value={values.risk_low_accpt_level}
        label={'Risk lowered to lowest acceptable level'}
        name={'risk_low_accpt_level'}
        showInfoIcon={false}
        disabled={readOnly}
      />
      <div className="heading">
        {`The team has determined with the inherently safe design measures,
        safeguarding, and information for use that "${eventName}" during
        "${taskName}" has ${values.risk_low_accpt_level==1?"":'not'} been lowered to the lowest practicable level`}
      </div>
      <AddNote
        showNoteInput={showNoteInput}
        setShowNoteInput={setShowNoteInput}
        name={'risk_low_accpt_level_note'}
        value={values.risk_low_accpt_level_note}
        setFiledValue={setFieldValue}
        readOnly={readOnly}
      />
      <hr />
    </div>
  );
};

export default RiskLowered;
