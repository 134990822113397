import React, { useEffect, useMemo, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Table from '../../../../../components/table/table';
import { useDispatch } from 'react-redux';
import { addMachineLimits, getMachineLimits } from '../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice';
import { useSelector } from 'react-redux';
import { getMachineLimitsData, getRiskAssessmentCoreLoadingState } from '../../../../../redux/selectors/riskAssessmentCore';
import { useLocation } from 'react-router-dom';
import { assets } from '../../../../../utils/assetsSrc';
import ViewEditLimitsOfMachine from './view-edit-limits-of-machine';
import './limits-of-machine.scss'
import { LimitTypesIds, accountForFullNames, personellExposedFullNames } from '../../../../../utils/constants';
import { limitsRowData, usePersonelData } from './tableColsRows';
import Loader from '../../../../../components/loader/loader';

export default function LimitsOfMachine() {
    const location = useLocation()
    const selectedRiskAssessment = location.state.selectedRiskAssessment
    const dispatch = useDispatch()
    const isLoading = useSelector(getRiskAssessmentCoreLoadingState())
    const limitsData = useSelector(getMachineLimitsData())
    const [currentTab, setCurrentTab] = useState('space')
    const [showPopup, setShowPopup] = useState(false)
    const [showPopupMode, setShowPopupMode] = useState('')
    const [selectedRow, setSelectedRow] = useState({})
    const [personelData, setPersonelData] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        dispatch(getMachineLimits(selectedRiskAssessment.id))
    }, [])

    useEffect(() => {
        // const currentTabData = limitsData[currentTab] || []
        const selectedLimitsData = limitsData[currentTab]
        const currentTabData = limitsRowData[currentTab] || []
        currentTabData?.forEach(el => {
            let dataRecord = selectedLimitsData?.find(rec => rec.account_for === el.accountForId)
            // if (dataRecord?.id) {
                el.intended_use = dataRecord?.intended_use??''
                el.justification = dataRecord?.justification??''
                el.resonably_foreseeable_misuse = dataRecord?.resonably_foreseeable_misuse??''
                el['id'] = dataRecord?.id
           // }
        })
        setData([...currentTabData.sort((a, b) => a.order < b.order ? 1 : -1)])
        // Personel data for 'use' tab
        const currentUsePersonelData = usePersonelData
        currentUsePersonelData?.forEach(el => {
            let dataRecord = selectedLimitsData?.find(rec => rec.personel_exposed === el.personellExposedId)
            if (dataRecord?.id) {
                el.intended_use = dataRecord.intended_use
                el.justification = dataRecord.justification
                el.resonably_foreseeable_misuse = dataRecord.resonably_foreseeable_misuse
                el['id'] = dataRecord.id
            }
        })
        setPersonelData([...currentUsePersonelData])
    }, [currentTab, limitsData])

    // useEffect(() => {
    // const currentTabData = limitsData[currentTab]
    // setData(currentTabData)
    // }, [limitsData])

    const columns = [
        {
            accessor: "accountForName",
            Header: "What to account for",
            width: 300,
            Cell: ({ row }) => accountForFullNames[row.original.accountForName]
        },
        {
            accessor: "intended_use",
            Header: "Intended use",
            width: 200
        },
        {
            accessor: "resonably_foreseeable_misuse",
            Header: "Reasonably forseeable misuse",
            width: 200
        },
        {
            accessor: "justification",
            Header: "Logic or justification",
            width: 200
        },
        {
            accessor: "",
            Header: "Edit/View",
            width: 200,
            Cell: ({ row }) => <>
                <img src={assets.editIcon2} onClick={() => {
                    setShowPopupMode('edit');
                    setShowPopup(true);
                    setSelectedRow(row.original)
                }} />
                <img src={assets.eyeIcon} style={{ marginLeft: '20px' }} onClick={() => {
                    setShowPopupMode('view');
                    setShowPopup(true);
                    setSelectedRow(row.original)
                }} />
            </>
        }
    ]

    const usePersonelExposedColumns = [
        {
            accessor: "accountForName",
            Header: "Personel Exposed",
            width: 200,
            Cell: ({ row }) => personellExposedFullNames[row.original.personellExposedName]
        },
        {
            accessor: "intended_use",
            Header: "Intended use (including training)",
            width: 200
        },
        {
            accessor: "resonably_foreseeable_misuse",
            Header: "Reasonably forseeable misuse (including training)",
            width: 200
        },
        {
            accessor: "justification",
            Header: "Justification/Logic",
            width: 200
        },
        {
            accessor: "",
            Header: "Edit/View",
            width: 200,
            Cell: ({ row }) => <>
                <img src={assets.editIcon2} onClick={() => {
                    setShowPopupMode('edit');
                    setShowPopup(true);
                    setSelectedRow(row.original)
                }} />
                <img src={assets.eyeIcon} style={{ marginLeft: '20px' }} onClick={() => {
                    setShowPopupMode('view');
                    setShowPopup(true);
                    setSelectedRow(row.original)
                }} />
            </>
        }
    ]
    const personelCols = useMemo(() => usePersonelExposedColumns, [usePersonelExposedColumns])
    const personelrowData = useMemo(() => personelData, [personelData])

    const cols = useMemo(() => columns, [columns])
    const rowData = useMemo(() => data, [data])

    const createMachineLimit = (id, data) => {
        const payload = {
            type: LimitTypesIds[currentTab.toUpperCase()],
            account_for: data.accountForId,
            intended_use: data.intended_use,
            personel_exposed: data.personellExposedId,
            resonably_foreseeable_misuse: data.resonably_foreseeable_misuse,
            justification: data.justification,
            risk_assessment: selectedRiskAssessment.id
        }
        if(!payload.accountForId){
            delete payload.accountForId
        }else if(!payload.personel_exposed){
            delete payload.personel_exposed
        }
        // return
        // If no id present make a post call
        if (!id) {
            dispatch(addMachineLimits({
                payload, callback: (result) => {
                    dispatch(getMachineLimits(selectedRiskAssessment.id))
                    setShowPopup(false)

                }
            }))
        } else {
            dispatch(addMachineLimits({
                id,
                payload, callback: (result) => {
                    dispatch(getMachineLimits(selectedRiskAssessment.id))
                    setShowPopup(false)

                }
            }))
        }
    }
    return (
        <div className='limits-of-machine-wrapper'>
            <div className='heading'>Limits of Machine</div>
            <hr />
            <Tabs
                id="controlled-tab-example"
                activeKey={currentTab}
                onSelect={(k) => setCurrentTab(k)}
                className="mb-3"
                fill
            >
                <Tab eventKey="space" title="Space" />
                <Tab eventKey="use" title="Use" />
                <Tab eventKey="time" title="Time" />
                <Tab eventKey="other" title="Other" />
            </Tabs>
            <div style={{overflow:'auto',height:'68vh'}}>
                <div className='limits-table'>
                    <Table
                        columns={cols}
                        data={rowData}
                        isLoading={false}
                        height={'68vh'} />
                </div>

                {currentTab === 'use' && <div className='use-personel-table'>
                    <Table
                        columns={personelCols}
                        data={personelrowData}
                        isLoading={false}
                        height={'68vh'} />
                </div>}
            </div>
            {showPopup && <ViewEditLimitsOfMachine
                show={showPopup}
                setShow={setShowPopup}
                mode={showPopupMode}
                label={`Limits Of Machine (${currentTab})`}
                data={selectedRow}
                savelimitsData={createMachineLimit}
            />}
            {isLoading && <Loader />}
        </div>
    )
}
