import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import * as _ from 'lodash';
import './MultiSelectDropdown.scss';
import { ErrorMessage } from 'formik';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const isArrayEqual = function (x, y) {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelectDropdown({
  label,
  listItems,
  value,
  handleSelectedUsersChange,
  renderChipsInside = true,
  name,
  showError,

}) {
  const theme = useTheme();
  const [listOfItemsDisplayed, setListOfItemsDisplayed] = React.useState(
    listItems || [],
  );
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const handleChange = event => {
    const {
      target: {value},
    } = event;
    const latest = value[value.length - 1];
    setListOfItemsDisplayed(
      listOfItemsDisplayed.filter(item => item.id !== latest.id) || [],
    );
    const newSelectedUsers =[...selectedUsers, latest]
    setSelectedUsers(
      // On autofill we get a stringified value.
      newSelectedUsers
    );
    handleSelectedUsersChange(newSelectedUsers);
  };

  React.useEffect(() => {
    setListOfItemsDisplayed(
      value
        ? listItems.filter(f => !value.some(item => item.id === f.id))
        : listItems || [],
    );
    if (!value) {
      setSelectedUsers([]);
    }
  }, [listItems]);

  // React.useEffect(() => {
  //   if (!isArrayEqual(selectedUsers, value)) {
  //     handleSelectedUsersChange(selectedUsers);
  //   }
  // }, [selectedUsers]);

  React.useEffect(() => {
    if (value?.length) {
      setSelectedUsers(value);
    } else {
      setSelectedUsers([]);
    }
  }, [value]);

  const handleDeleteSelected = user => {
    setListOfItemsDisplayed([
      ...listOfItemsDisplayed,
      listItems.find(item => item.id === user.id),
    ]);
    const newSelectedUsers = selectedUsers.filter(item => item.id !== user.id)
    setSelectedUsers(newSelectedUsers);
    handleSelectedUsersChange(newSelectedUsers)
  };

  return (
    <div className="mb-4">
      <FormControl sx={{m: 0, width: '100%'}}>
        {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
        <div className="multi-select-chip">
          <label htmlFor="demo-multiple-chip">{label}</label>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            name="demo-multiple-chip"
            multiple
            value={selectedUsers}
            onChange={handleChange}
            style={{width: '100%'}}
            //   input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={selected =>
              renderChipsInside ? (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                  {selected.length &&
                    selected.map(value => (
                      <Chip
                        onMouseDown={event => {
                          event.stopPropagation();
                        }}
                        key={value?.id}
                        label={value?.fullname || value?.name}
                        onDelete={() => handleDeleteSelected(value)}
                      />
                      // <span key={value}>{value}</span>
                    ))}
                </Box>
              ) : (
                <></>
              )
            }
            MenuProps={MenuProps}>
            {listOfItemsDisplayed.length &&
              listOfItemsDisplayed?.map(item => (
                <MenuItem
                  key={item.id}
                  value={item}
                  // style={getStyles(item.name, personName, theme)}
                >
                  {item.fullname || item.name}
                </MenuItem>
              ))}
          </Select>
          {!renderChipsInside && (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
              {!!selectedUsers.length &&
                selectedUsers.map(value => (
                  <Chip
                    onMouseDown={event => {
                      event.stopPropagation();
                    }}
                    key={value?.id}
                    label={value?.fullname || value?.name}
                    onDelete={() => handleDeleteSelected(value)}
                  />
                  // <span key={value}>{value}</span>
                ))}
            </Box>
          )}
          {showError && (
            <ErrorMessage component="div" name={name} className="error" />
          )}
        </div>
      </FormControl>
    </div>
  );
}
