export const sessionAttributes = {
    selectedProductLine: 'selectedProductLine'
}

export const setItemInSession = (attribute,value) => {
    sessionStorage.setItem(attribute,value)
}

export const getItemFromSession = (attribute) => {
   return sessionStorage.getItem(attribute)
}