import React, { useEffect, useState } from "react"
import "./revision-history.scss"
import Button from "../../../../../components/form/button/button"
import AddEditRevisionHistory from "./add-edit-revision-history"
import { useLocation } from "react-router-dom"
import {
  getRevisionHistoryData,
  getRiskAssessmentCoreLoadingState
} from "../../../../../redux/selectors/riskAssessmentCore"
import { useDispatch } from "react-redux"
import {
  addRevisionHistory,
  getRevisionHistory
} from "../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice"
import Loader from "../../../../../components/loader/loader"
import { useSelector } from "react-redux"
import Table from "../../../../../components/table/table"
import { assets } from "../../../../../utils/assetsSrc"
import { changeDateFormat } from "../../../../../utils/DateUtils"

const RevisionHistory = () => {
  const [showModel, setShowModel] = useState(false)
  const [selectedRH, setSelectedRH] = useState()

  const dispatch = useDispatch()
  const location = useLocation()
  const selectedRAId = location.state.selectedRiskAssessment.id
  const revisionHistoryData = useSelector(getRevisionHistoryData())
  const isLoading = useSelector(getRiskAssessmentCoreLoadingState())

  useEffect(() => {
    dispatch(getRevisionHistory(selectedRAId))
  }, [])

  const handleAddEdit = payload => {
    payload.risk_assessment = selectedRAId
    const id = selectedRH?.id
    dispatch(
      addRevisionHistory({
        id,
        payload,
        callback: result => {
          dispatch(getRevisionHistory(selectedRAId))
          setShowModel(false)
        }
      })
    )
  }
  const handleEdit = revisionHistory => {
    setSelectedRH(revisionHistory)
    setShowModel(true)
  }

  const columns = [
    // {
    //   accessor: "id",
    //   Header: "No.",
    //   width: 50
    // },
    {
      accessor: "description",
      Header: "Description",
      width: 300
    },
    {
      accessor: "revision_number",
      Header: "Revision No."
      // width: 200
    },
    {
      accessor: "date",
      Header: "Date",
      Cell: ({ row }) =>
        changeDateFormat(row.original.date, "YYYY-MM-DD", "MM/DD/YYYY")
      // width: 200
    },
    {
      accessor: "",
      Header: " ",
      // width: 200,
      Cell: ({row}) => (
        <div className="user-actions">
          <img
            className="edit"
            src={assets.editIcon}
            onClick={() => {
              handleEdit(row.original)
            }}
          />
        </div>
      )
    }
  ]
  return (
    <div className="revision-history-wrapper">
      <div className="d-flex justify-content-between align-items-center">
        <div className="heading">Revision History</div>
        <Button
          type="submit"
          variant={"submit-button"}
          clickHandler={() => {
            setSelectedRH(undefined)
            setShowModel(true)
          }}
        >
          Add
        </Button>
      </div>
      <hr />

      <div className="revision-history-table">
        <Table
          columns={columns}
          data={revisionHistoryData}
          isLoading={false}
          height={"68vh"}
          initialSortKey={'id'}
        />
      </div>

      <AddEditRevisionHistory
        show={showModel}
        setShow={setShowModel}
        handleAddEdit={handleAddEdit}
        revisionHistory={selectedRH}
      />
      {isLoading && <Loader />}
    </div>
  )
}

export default RevisionHistory
