import React, { useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./productLineCard.scss"
import Button from "../../../../../components/form/button/button"
import { assets } from "../../../../../utils/assetsSrc"

export default function ProductLineCard({
  id,
  name,
  image,
  addEditMachine,
  clickHandler,
  handleDelete,
  handleEdit,
}) {
  const buttonRoundStyles = {
    borderRadius: "30px"
  }
  const [showMoreOptions, setShowMoreOptions] = useState(false)

  return (
    <div
      className={`product-line-card-wrapper ${
        !addEditMachine && "product-line-card-height"
      }`}
    >
      <Row className="product-line-card-inner">
        <div className="more-action-button">
          {showMoreOptions && (
            <span className="more-options">
              <span
                className="delete"
                onClick={e => {
                  e.stopPropagation()
                  handleDelete()
                }}
              >
                <img src={assets.trash} />
              </span>
              <span
                className="edit"
                onClick={e => {
                  e.stopPropagation()
                  handleEdit()
                }}
              >
                <img src={assets.editIcon2} />
              </span>
            </span>
          )}
          {addEditMachine && (
            <img
              style={{ padding: "10px", width: 42 }}
              src={assets.moreIcon}
              onClick={e => {
                e.stopPropagation()
                setShowMoreOptions(!showMoreOptions)
              }}
            />
          )}
        </div>
        <div>
          <Col xs="12" className="pl-image">
            <img src={image} alt={`${name} image`} />
          </Col>
        </div>
        <div>
          <Col xs="12" className="pl-name">
            {name}
          </Col>
          {addEditMachine && (
            <Col xs="12" className="pl-model-add">
              <Button
                variant="secondary-button"
                clickHandler={() => clickHandler("add", id)}
                styles={buttonRoundStyles}
              >
                Add new Model
              </Button>
            </Col>
          )}
          <Col xs="12" className="pl-model-view">
            <Button
              variant="submit-button"
              clickHandler={() => clickHandler("view", id)}
              styles={buttonRoundStyles}
            >
              View Models
            </Button>
          </Col>
          {/* {addEditMachine && <Col xs="12" className='pl-model-edit'>
                        <Button variant='submit-button'
                            clickHandler={() => clickHandler('edit', id)}
                            styles={buttonRoundStyles}>
                            Edit Models
                        </Button>
                    </Col>} */}
        </div>
      </Row>
    </div>
  )
}
