import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { assets } from '../../utils/assetsSrc'
import './alert.scss'
import Button from '../form/button/button'

export default function Alert({ label, show, setShow, helpText }) {
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="alert-box"
            centered
            className="alert-box">
            <Modal.Body className="p-3 px-3 text-center">
                <div className='close-icon'>
                    <img onClick={() => setShow(false)} src={assets.closeIcon} />
                </div>
                <div className='main-content'>
                    <div className='header'>
                        <p className="label mt-3 mb-4">{label}</p>
                    </div>
                    <div>{helpText}</div>
                    <div className='action-btns'>
                        <Button
                            variant='submit-button' clickHandler={()=>setShow(false)}>{'OK'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
