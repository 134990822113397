import { useSelector } from "react-redux"
import { createSelector } from "@reduxjs/toolkit"

const riskAssessmentCoreState = state => {
  return state.persistedReducer.riskAssessmentCoreReducer
}

export const getRiskAssessmentCoreTasksState = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.riskAssessmentTasks || []
  })

export const getRiskAssessmentTaskMapping = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.task_event_mapping || []
  })

  export const getTaskDetailState = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.task_detail
  })


export const getMachineLimitsData = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.machineLimits || {}
  })

  export const getAssumptionData = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.assumptions?.[0] || {}
  })

  export const getRevisionHistoryData = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.revisionHistory || []
  })

  export const getAttendanceData = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.attendance || []
  })

  export const getEmployeeData = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.employee || []
  })


export const getRiskAssessmentCoreLoadingState = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.isLoading || false
  })

  export const getSummaryData = () =>
  createSelector(riskAssessmentCoreState, state => {
    return state?.summary || []
  })


