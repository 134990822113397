import React from 'react';
import { Navigate, useRoutes, Routes, Route } from 'react-router-dom';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';
import OnBoardingSlides from './pages/onboarding/onboardingSlides';
import Signup from './pages/signup/signup';
import ProtectedHOC from './utils/ProtectedHOC';
import ForgotPasswordContainer from './pages/forgot-password-container/container';
import FileUpload from './pages/file-upload/fileUpload';
import KnowledgeHub from './pages/dashboard/knowlwdge-hub/knowledge-hub';
import Layout from './components/layout/layout';
import UserList from './pages/dashboard/user-list/user-list';
import Assessment from './pages/dashboard/risk-assessment/assessment';
import ProductLine from './pages/dashboard/risk-assessment/pages/product-line/productLine';
import ProductModel from './pages/dashboard/risk-assessment/pages/productModel/productModel';
import { allRoutes } from './utils/routeConstants';
import { all } from 'axios';
import ProductLineAccess from './pages/dashboard/user-list/product-line-access';
import RestrictedRiskAssessment from './pages/dashboard/user-list/restricted-risk-assessment';
import UserListMain from './pages/dashboard/user-list/user-list-main';
import RiskAssessment from './pages/dashboard/risk-assessment/pages/risk-assessments/riskAssessment';
import SpecificRiskAssessment from './pages/dashboard/risk-assessment/pages/specific-risk-assessment/specific-risk-assessment';
import TaskDetails from './pages/dashboard/risk-assessment/pages/task-details/task-details';
import EventDetail from './pages/dashboard/risk-assessment/pages/event-details/event-details';
import ShareRiskAssessment from './pages/dashboard/risk-assessment/pages/share-risk-assessment/share-risk-assessment';
import LimitsOfMachine from './pages/dashboard/risk-assessment/pages/limits-of-machine/limits-of-machine';
import DeletedRiskAssessment from './pages/dashboard/delete-risk-assessment/deletedRiskAssessment';
import Feedback from './pages/feedback/feedback';
import ChangePassword from './pages/dashboard/change-password/changePassword';
import RiskAssessmentInfo from './pages/dashboard/risk-assessment/pages/risk-assessment-info/risk-assessment-info';
import Assumptions from './pages/dashboard/risk-assessment/pages/assumptions/assumptions';
import RevisionHistory from './pages/dashboard/risk-assessment/pages/revision-history/revision-history';
import AttendanceRecord from './pages/dashboard/risk-assessment/pages/attendance/attendance-record';
import Summary from './pages/dashboard/risk-assessment/pages/summary/summary';

export default function AppRoutes() {
  const routes = <Routes>
    {/* <Route path='/home/:loc?' element={<OnBoardingSlides />} /> */}
    <Route path='/' element={<OnBoardingSlides />} />
    <Route path={`${allRoutes.home}/:loc?`} element={<OnBoardingSlides />} />
    <Route path={allRoutes.login} element={<Login />} />
    <Route path={allRoutes.signup} element={<Signup />} />
    <Route path={allRoutes.resetPassword} element={<ForgotPasswordContainer />} />
    <Route path={allRoutes.shareRiskAssessment} element={<ShareRiskAssessment />} />
    <Route element={<ProtectedHOC><Layout /></ProtectedHOC>}>
      {/* <Route element={<Layout />}> */}
      <Route path={allRoutes.dashboardRoutes.index} element={<Dashboard />} >
        <Route index element={<Navigate to={allRoutes.dashboardRoutes.assessment} />} />
        <Route path={allRoutes.dashboardRoutes.userList.index} element={<UserListMain />} >
          <Route index element={<Navigate to={allRoutes.dashboardRoutes.userList.main} />} />
          <Route path={allRoutes.dashboardRoutes.userList.main} element={<UserList />}></Route>
          <Route path={allRoutes.dashboardRoutes.userList.productLine} element={<ProductLineAccess />}></Route>
          <Route path={allRoutes.dashboardRoutes.userList.restrictedRA} element={<RestrictedRiskAssessment />}></Route>
        </Route>
        {/* <Route path={allRoutes.dashboardRoutes.knowledgeHub} element={<KnowledgeHub />} /> */}
        <Route path={allRoutes.dashboardRoutes.assessment} element={<Assessment />}>
          <Route index element={<Navigate to={allRoutes.dashboardRoutes.assessmentRoutes.productLine} />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.productLine} element={<ProductLine />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.productModels} element={<ProductModel />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.riskAssessments} element={<RiskAssessment />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.specificRiskAssessment} element={<SpecificRiskAssessment />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.taskDetails} element={<TaskDetails />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.eventDetails} element={<EventDetail />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.limitsOfMachine} element={<LimitsOfMachine />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.riskAssessmentInfo} element={<RiskAssessmentInfo />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.assumptions} element={<Assumptions />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.revisionHistory} element={<RevisionHistory />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.attendanceRecord} element={<AttendanceRecord />} />
          <Route path={allRoutes.dashboardRoutes.assessmentRoutes.summary} element={<Summary />} />

        </Route>
        <Route path={allRoutes.dashboardRoutes.deletedAssessment} element={<DeletedRiskAssessment />} />

      </Route>
      <Route path={allRoutes.dashboardRoutes.changePassword} element={<ChangePassword />}></Route>
      <Route path={allRoutes.feedback} element={<Feedback />}></Route>
      <Route path='/upload' element={<FileUpload />} />
      {/* </Route> */}
    </Route>
    <Route path="*" element={<Navigate to={allRoutes.dashboardRoutes.index} replace />} />
  </Routes>
  return routes
}
