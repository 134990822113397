import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getLoginStateSelector } from '../redux/selectors/loginSelector';

export default function ProtectedHOC({children}) {
    const loginState = useSelector(getLoginStateSelector());
    const { isLoggedIn } = loginState
    const location = useLocation();
    // if(!isLoggedIn && location.state.path === '/signup'){
    //     return <Navigate to="/signup" state={{ from: location.pathname }} replace={true}/>;
    // }
    if(isLoggedIn) return children;
    return <Navigate to="/login" state={{ from: location.pathname }} replace={true}/>;
}
