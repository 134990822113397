import React from "react"
import "./radioGroup.scss"
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"

export default function RadioButton({ styles, items, name, value, onChange, disabled }) {
  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup
          name={name}
          value={value}
          onChange={event => {
            onChange(event)
          }}
        >
          {items.map(function (item) {
            return (
              <FormControlLabel
                control={<Radio />}
                label={item.label}
                value={item.value}
                disabled={disabled}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
