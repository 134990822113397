import React, {useEffect, useRef, useState} from 'react';
import Button from '../../../../../../../components/form/button/button';
import {assets} from '../../../../../../../utils/assetsSrc';
import {useLocation, useNavigate} from 'react-router-dom';
import SearchSelect from '../../../../components/virtualized-select/searchSelect';
import {useDispatch, useSelector} from 'react-redux';
import {
  addSubCategoryEvents,
  deleteSubCategoryEvents,
  getRiskAssessmentTasks,
  getTaskDetail,
  importEvent,
  markNoExpToUnWantedEvents,
} from '../../../../../../../redux/risk-assessment-core/riskAssessmentCoreSlice';
import {allRoutes} from '../../../../../../../utils/routeConstants';
import Alert from '../../../../../../../components/alert/alert';
import SubCategoryEvents from './event-sub-category-row';
import ViewInfo from '../../../../components/view-info/view-info';
import {
  eventRowInfoIconEnum,
  getUserRoleName,
} from '../../../../../../../utils/constants';
import ImportEvent from '../../../../components/import-event/importEvent';
import {getLoginStateSelector} from '../../../../../../../redux/selectors/loginSelector';

const SubCategoryDropdown = ({handleListChange}) => {
  const [selectedList, setSelectedList] = React.useState([]);
  return (
    <div className="sub-category-wrapper">
      <SearchSelect handleListChange={data => setSelectedList(data)} />
      <a className="add-button" onClick={() => handleListChange(selectedList)}>
        Add
      </a>
    </div>
  );
};

export default function EventRow({data}) {
  const loginState = useSelector(getLoginStateSelector());
  const {loggedInUser} = loginState;
  const [showNoteInput, setShowNoteInput] = useState(data?.note?.length > 0);
  const [isExposedInput, setIsExposedInput] = useState('');
  const [isExposedInputYes, setIsExposedInputYes] = useState(false);
  const [isExposedInputNo, setIsExposedInputNo] = useState(false);
  const [addSubCategory, setAddSubCategory] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [noClickHelpText, setNoClickHelpText] = useState('');
  const [parentNoteValue, setParentNoteValue] = useState(data.note || '');
  const [subCategoryList, setSubCategoryList] = useState(
    data?.sub_cat_data || [],
  );
  const [showImportModal, setShowImportModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const nav = useNavigate();
  const readOnly = getUserRoleName(loggedInUser?.role) === 'general';

  useEffect(() => {
    setSubCategoryList(data?.sub_cat_data || []);
  }, [data?.sub_cat_data]);

  const handleEventSelection = (eventName, id) => {
    nav(allRoutes.dashboardRoutes.assessmentRoutes.eventDetails, {
      state: {...location.state, selectedEventName: eventName, eventId: id},
    });
  };
  useEffect(() => {
    if (subCategoryList.length) {
    }
  }, [subCategoryList]);

  useEffect(() => {
    const expSelection =
      data.exp_unwntd_events === 1
        ? 'yes'
        : data.exp_unwntd_events === 0
        ? 'no'
        : '';
    setIsExposedInput(expSelection);
  }, [data]);

  useEffect(() => {
    if (isExposedInput === 'yes') {
      setIsExposedInputYes(true);
      setIsExposedInputNo(false);
    } else {
      setIsExposedInputNo(true);
      setIsExposedInputYes(false);
    }
  }, [isExposedInput]);

  const handleNoClicked = () => {
    if (subCategoryList.length) {
      setShowAlert(true);
      const text = [
        'Please delete Event Subcategories for event ',
        "'",
        <strong>{data.event_detail.name}</strong>,
        "'",
      ];
      setNoClickHelpText(text);
      return;
    }
    setIsExposedInput('no');
    setAddSubCategory(false);
    // setShowNoteInput(false)
    setSubCategoryList([]);
    setNoExposureUnwntdEvents();
  };

  const handleYesClicked = () => {
    setIsExposedInput('yes');
    const eventMappingId = data.id;
    const payload = {
      task: data.task,
      event: data.event,
      exp_unwntd_events: 1,
    };
    dispatch(
      markNoExpToUnWantedEvents({
        id: eventMappingId,
        payload,
        callback: result => {
          // console.log("Marked no success", result)
        },
      }),
    );
  };

  // marking No to an event
  const setNoExposureUnwntdEvents = () => {
    const eventMappingId = data.id;
    const payload = {
      task: data.task,
      event: data.event,
      exp_unwntd_events: 0,
      note: parentNoteValue,
    };
    dispatch(
      markNoExpToUnWantedEvents({
        id: eventMappingId,
        payload,
        callback: result => {
          // console.log("Marked no success", result)
        },
      }),
    );
  };

  const addNoteToEventSubCat = (subCatId, note) => {
    const eventMappingId = subCatId;
    const payload = {
      note: note,
    };
    // using same 'mark no' patch API. Rename required during code refactor
    dispatch(
      markNoExpToUnWantedEvents({
        id: eventMappingId,
        payload,
        callback: result => {
          // console.log("Marked no success", result)
        },
      }),
    );
  };

  const handleSubCategoryListChange = subCategoryListData => {
    const payload = subCategoryListData.map(el => {
      return {
        task: data.task,
        event: data.event,
        exp_unwntd_events: 1,
        event_sub_category: el.id,
      };
    });
    dispatch(
      addSubCategoryEvents({
        payload,
        callback: result => {
          if (result.status === 200) {
            setSubCategoryList([...subCategoryList, ...result.data]);
          }
          setAddSubCategory(false);
        },
      }),
    );
  };

  const handldeSubCatDelete = (eventMappingId, id) => {
    const payload = {
      task: data.task,
      event: data.event,
      event_sub_category: id,
    };
    dispatch(
      deleteSubCategoryEvents({
        id: eventMappingId,
        payload,
        callback: result => {
          if (result.data === '') {
            setSubCategoryList(
              subCategoryList.filter(
                el => el.event_sub_category_detail?.id !== id,
              ),
            );
          }
          setAddSubCategory(false);
        },
      }),
    );
  };
  const handleImportEventAction = payload => {
    dispatch(
      importEvent({
        payload,
        callback: resp => {
          setShowImportModal(false);
          const id = location?.state?.selectedTask.id
          dispatch(
            getTaskDetail({
              id,
              callback: resp => {
                
              },
            }),
          );
        },
      }),
    );
  };

  return (
    <div className="event-row-wrapper">
      <div>
        <div className="row-1">
          <div className="heading">
            <span className="number">{data?.event_detail?.event_sequence}</span>

            <div className="name-note-container">
              <span className="name">{data?.event_detail?.name}</span>
              {data.note && <div className="note">{data.note}</div>}
            </div>

            {/* <span className="name">{data?.event_detail?.name}</span> */}
          </div>
          <div className="more-details">
            <div className="info-events">
              <ViewInfo label={eventRowInfoIconEnum[data?.event_detail?.id]} />
            </div>
            {subCategoryList.length == 0 && !readOnly && (
              <div
                className="copy-events"
                onClick={() => setShowImportModal(true)}>
                <img style={{marginRight: '5px'}} src={assets.copyIcon} />
                Import
              </div>
            )}

            <div className="unwanted-events">
              <span className="text">Exposure to unwanted event</span>
              <span className="action-btns">
                <Button
                  clickHandler={() => handleYesClicked()}
                  variant={
                    isExposedInput === 'yes'
                      ? 'submit-button'
                      : 'secondary-button'
                  }
                  disabled={readOnly}>
                  Yes
                </Button>
                <Button
                  clickHandler={handleNoClicked}
                  variant={
                    isExposedInput === 'no'
                      ? 'submit-button'
                      : 'secondary-button'
                  }
                  disabled={readOnly}>
                  No
                </Button>
              </span>
            </div>
            {isExposedInputYes && !subCategoryList.length && (
              <span
                className="forward-arrow"
                onClick={() =>
                  handleEventSelection(data?.event_detail?.name, data?.id)
                }>
                <img src={assets.chevronRight} />
              </span>
            )}
          </div>
        </div>
        {/* If No clicked */}
        {isExposedInput === 'no' && !readOnly && (
          <div className="row-2">
            {!showNoteInput ? (
              <span onClick={() => setShowNoteInput(true)}>
                <img src={assets.addFilled} />
                <span className="add-note-text">
                  {data.note ? 'Edit Note' : 'Add note'}
                </span>
              </span>
            ) : (
              <div className="note-row">
                <label htmlFor="note-input">Note (optional)</label>
                <input
                  id="note-input"
                  value={parentNoteValue}
                  onChange={e => setParentNoteValue(e.target.value)}
                  type="text"
                />

                <span style={{display: 'flex'}}>
                  <img
                    style={{scale: '0.6'}}
                    onClick={handleNoClicked}
                    src={assets.checkIcon}
                  />
                  <img
                    style={{scale: '0.6'}}
                    onClick={() => setShowNoteInput(false)}
                    src={assets.closeIcon}
                  />
                </span>
              </div>
            )}
          </div>
        )}
        {/* If yes clicked */}
        {((isExposedInput === 'yes' && !readOnly && !addSubCategory) ||
          !!subCategoryList.length) && (
          <div className="row-2">
            {!!!subCategoryList.length && (
              <>
                {!showNoteInput ? (
                  <span onClick={() => setShowNoteInput(true)}>
                    <img src={assets.addFilled} />
                    <span className="add-note-text">
                      {data.note ? 'Edit Note' : 'Add note'}
                    </span>
                  </span>
                ) : (
                  <div className="note-row">
                    <label htmlFor="note-input">Note (optional)</label>
                    <input
                      id="note-input"
                      value={parentNoteValue}
                      onChange={e => setParentNoteValue(e.target.value)}
                      type="text"
                    />

                    <span style={{display: 'flex'}}>
                      <img
                        style={{scale: '0.6'}}
                        onClick={handleNoClicked}
                        src={assets.checkIcon}
                      />
                      <img
                        style={{scale: '0.6'}}
                        onClick={() => setShowNoteInput(false)}
                        src={assets.closeIcon}
                      />
                    </span>
                  </div>
                )}
              </>
            )}
            {isExposedInput === 'yes' && !readOnly && (
              <span
                className="event-sub-category-text"
                onClick={() => setAddSubCategory(true)}>
                Add event subcategory
              </span>
            )}
          </div>
        )}
        {addSubCategory && (
          <SubCategoryDropdown handleListChange={handleSubCategoryListChange} />
        )}
      </div>
      {!!subCategoryList.length &&
        subCategoryList.map((subCat, idx) => (
          <SubCategoryEvents
            subCat={subCat}
            idx={idx}
            handleEventSelection={() =>
              handleEventSelection(
                `${data?.event_detail?.name} - ${subCat?.event_sub_category_detail?.name}`,
                subCat?.id,
              )
            }
            handldeSubCatDelete={handldeSubCatDelete}
            addNoteToEventSubCat={addNoteToEventSubCat}
            readOnly={readOnly}
          />
        ))}
      {showAlert && (
        <Alert
          label={'Alert'}
          show={showAlert}
          setShow={setShowAlert}
          helpText={noClickHelpText}
        />
      )}
      {showImportModal && (
        <ImportEvent
          label={'Import'}
          show={showImportModal}
          setShow={setShowImportModal}
          data={data}
          handleImportEventAction={handleImportEventAction}
        />
      )}
    </div>
  );
}
