import React from 'react'
import { Modal } from 'react-bootstrap'
import { assets } from '../../../../../utils/assetsSrc'
import { Form, Formik } from 'formik'
import TextField from '../../../../../components/form/textfield/textfield'
import './limits-of-machine.scss'
import Button from '../../../../../components/form/button/button'
import { accountForFullNames, personellExposedFullNames } from '../../../../../utils/constants'

const TextArea = ({ label, height, ...props}) => {
    return (<div style={{position:'relative',marginBottom:'10px'}}>
        <label style={{position:'absolute', left:'10px', top:'-13px', background:'white', padding:'0 4px'}}>{label}</label>
        <textarea wrap="soft" style={{width:'100%', maxHeight:'190px',minHeight:height||'190px',resize:'none',padding:'10px'}} {...props} />
    </div>)
}

export default function ViewEditLimitsOfMachine({ show, setShow, label, data, mode, savelimitsData }) {
    const initialValues = {
        accountFor: accountForFullNames[data.accountForName] || '',
        accountForId: data.accountForId,
        personellExposed: personellExposedFullNames[data.personellExposedName] || '',
        personellExposedId: data.personellExposedId,
        intended_use: data.intended_use || '',
        resonably_foreseeable_misuse: data.resonably_foreseeable_misuse || '',
        justification: data.justification || '',
    }

    const handleSubmit = (vals) => {
        savelimitsData(data.id,vals)
    }
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="add-edit-machine-limits"
            centered
            size='md'
            className="view-edit-machine-limits">
            <div className='header'>
                <p className="label mt-3 mb-4">{label}</p>
                <img onClick={() => setShow(false)} src={assets.closeIcon} />
            </div>
            <Formik
                initialValues={initialValues}
                // validationSchema={validate}
                onSubmit={handleSubmit}
            >
                {({ formik, values, setFieldValue, isSubmitting, isValid, errors }) => (
                    <Form>
                        <div>
                            <TextArea name='accountFor' type='text'
                                label='What to account for'
                                placeholder=""
                                value={values['accountFor'] || values['personellExposed']}
                                height={'48px'}
                                disabled />
                        </div>
                        <div>
                            <TextArea name='intended_use' type='text'
                                label='Intended use'
                                placeholder=""
                                value={values['intended_use']}
                                onChange={(e)=>setFieldValue('intended_use',e.target.value)}
                                disabled = {mode === "view"}
                            />
                        </div>
                        <div>
                            <TextArea name='resonably_foreseeable_misuse' type='text'
                                label='Reasonably forseeable misuse'
                                placeholder=""
                                value={values['resonably_foreseeable_misuse']}
                                onChange={(e)=>setFieldValue('resonably_foreseeable_misuse',e.target.value)}
                                disabled = {mode === "view"}
                            />
                        </div>
                        <div>
                            <TextArea name='justification' type='text'
                                label='Logic or justification'
                                placeholder=""
                                value={values['justification']}
                                onChange={(e)=>setFieldValue('justification',e.target.value)}
                                disabled = {mode === "view"}
                            />
                        </div>
                        <div className='action-btns'>
                            {mode==='edit' && <Button type='submit' variant={'submit-button'}>Save</Button>}
                            <Button type='button' clickHandler={()=>setShow(false)} variant={mode==='view'?'submit-button':'secondary-button'}>{mode==='view'?'OK':'Close'}</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}
