import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

// All styles and static import goes here
import { assets } from '../../utils/assetsSrc';

// import login actions
import { initLogin } from '../../redux/login/loginSlice';

// import all custom components
import SplashWrapper from '../../components/splash/splash-wrapper';
import TextField from '../../components/form/textfield/textfield';

import './signup.scss';
import Button from '../../components/form/button/button';
import { clearSignupState, initSignup } from '../../redux/signup/signupSlice';
import { getSignupStateSelector } from '../../redux/selectors/signupSelector';

export default function Signup() {
    const termsCheckRef = useRef()
    const signupState = useSelector(getSignupStateSelector());
    const dispatch = useDispatch();
    const nav = useNavigate();
    // signUp type(success|failure)
    const { type } = signupState.signUpResponse;
    const emailInitialValue = sessionStorage.getItem('getStartEmail') || ''

    const initialValues = {
        email: emailInitialValue,
        jobTitle:'',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false
    }

    const formFieldStyles = {
        // height: '48px',
        // width: '400px'
    }

    const validate = Yup.object({
        firstName: Yup.string().required('First Name is Required'),
        lastName: Yup.string().required('First Name is Required'),
        email: Yup.string().email("Email is invalid!").required("Email Required!"),
        jobTitle: Yup.string().required("Job Title is Required!"),
        password: Yup.string().required("Password Required!"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required("Confrm Password Required!"),
        // termsAndConditions: Yup.bool()
        //     .oneOf([true], 'You need to accept the terms and conditions'),
    });

    //   const handleLogin = () => {
    //     dispatch(initLogin({ userName: 'shivam', password: 'Hello@1234' }))
    //   }

    useEffect(() => {
        if (type === 'success') {
            // [TODO] show success message
            // dispatch clear state
            dispatch(clearSignupState())
            nav('/login')
        } else if (type === 'failure') {
            // [TODO] show failure message
        }
    }, [type]);

    useEffect(()=>{
        return () => sessionStorage.clear()
    },[])

    const handleRegister = (values) => {
        const payload = {
            name: values.firstName,
            email: values.email,
            job_title: values.jobTitle,
            password: values.password
        }
        dispatch(initSignup(payload));
    }

    const handleTermsCheck = (event) => {
        termsCheckRef.current = event.target.checked;
    }

    const SignupForm = () => {
        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    onSubmit={handleRegister}
                >
                    {({ formik, isSubmitting, isValid, errors }) => (
                        <Form>
                            <div className='signupForm'>
                                <div className='signup-heading'>Register</div>
                                <TextField
                                    type="text"
                                    label="Email ID"
                                    name="email"
                                    placeholder="Enter email"
                                    styles={formFieldStyles}
                                    suffixIcon="email"
                                />
                                <TextField
                                    type="text"
                                    label="Job Title"
                                    name="jobTitle"
                                    placeholder="Consultant"
                                    styles={formFieldStyles}
                                />
                                <TextField
                                    type="text"
                                    label="First name"
                                    name="firstName"
                                    placeholder="John"
                                    styles={formFieldStyles}
                                />
                                <TextField
                                    type="text"
                                    label="Last name"
                                    name="lastName"
                                    placeholder="Doe"
                                    styles={formFieldStyles}
                                />
                                <TextField
                                    type="password"
                                    label="Create Password"
                                    name="password"
                                    placeholder="Enter password"
                                    styles={formFieldStyles}
                                    suffixIcon="closeEye"
                                />
                                <TextField
                                    type="password"
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    placeholder="Confirm password"
                                    styles={formFieldStyles}
                                    suffixIcon="closeEye"
                                />
                                {/* <div className='terms-conditions'>
                                    <div>
                                        <span className='signup-checkbox'>
                                            <Field type="checkbox" name="termsAndConditions" />
                                        </span>
                                        I agree to the &nbsp;<a href="https://www.komatsu.com/en/privacy-statement/" target="_blank" className='link'>
                                            Terms & Conditions</a>&nbsp; and &nbsp; 
                                            <a href="https://www.komatsu.com/en/privacy-statement/" target="_blank" className='link'>Privacy Policy</a>
                                    </div>
                                    {errors.termsAndConditions && <div className='error-message'>{errors.termsAndConditions}</div>}
                                </div> */}
                                <div>
                                    <Button
                                        type="submit"
                                        variant='submit-button'
                                        styles={formFieldStyles}
                                        // clickHandler={handleRegister}
                                        disabled={isSubmitting}
                                    >Register</Button>
                                </div>
                                <div className='register-account'>
                                    <span className='first'>Already have an Account? &nbsp;</span>
                                    <span onClick={() => nav('/login')} className='second'>Login</span>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }

    return (
        <>
            <SplashWrapper>
                <div className='signup-wrapper'>
                    <img className='logo' src={assets.komatsuLogo} />
                    <SignupForm />
                </div>
            </SplashWrapper>
        </>
    )
}
