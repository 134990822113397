import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

// API function import
import { apiFunctions } from '../../api/functions';

// Reducer import from loginSlice
import { signupSuccess, signupFailure } from './signupSlice';
import { errorHandler } from '../../utils/errorHandling';

const signupAPI = apiFunctions.signup;

// signup Utility
const userRegistered = (result) => {
    if (result.hasOwnProperty('email') && result.hasOwnProperty('id') && result.hasOwnProperty('name')) {
        return { type: 'success', message: 'User Registered Successfully' }
    } else {
        return { type: 'failure', message: 'some error occured' }
    }
}


// signup Handler starts

async function signupHandler(payload) {
    return await signupAPI(payload);
}

// signup Handler ends

function* signup(action) {
    try {
        const result = yield call(signupHandler, action.payload);
        const response = userRegistered(result.data)
        yield put(signupSuccess({ response }))
    }
    catch (error) {
        const response = errorHandler(error)
        yield put(signupFailure({ response }))
    }
}

export function* signupWatcher() {
    yield takeLatest('signup/initSignup', signup)
}