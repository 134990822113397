import React, { useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "../../../../../components/form/button/button"
import "./productModelCard.scss"
import { assets } from "../../../../../utils/assetsSrc"

export default function ProductModelCard({
  machineData,
  clickHandler,
  hasWritePrivileges,
  handleEdit,
  handleDelete
}) {
  const buttonRoundStyles = {
    borderRadius: "5px"
  }
  const [showMoreOptions, setShowMoreOptions] = useState(false)

  return (
    <div className="product-model-card-wrapper">
      <Row className="product-model-card-inner">
        <div className="more-action-button">
          {showMoreOptions && (
            <span className="more-options">
              <span
                className="delete"
                onClick={e => {
                  e.stopPropagation()
                   handleDelete()
                }}
              >
                <img src={assets.trash} />
              </span>
              <span
                className="edit"
                onClick={e => {
                  e.stopPropagation()
                  handleEdit()
                }}
              >
                <img src={assets.editIcon2} />
              </span>
            </span>
          )}
          {hasWritePrivileges && (
            <img
              style={{ padding: "10px", width: 42 }}
              src={assets.moreIcon}
              onClick={e => {
                e.stopPropagation()
                setShowMoreOptions(!showMoreOptions)
              }}
            />
          )}
        </div>
        <div>
          <Col xs="12" className="pm-image">
            <img src={machineData?.image} alt={`${machineData?.name} image`} />
          </Col>
        </div>
        <div>
          <Col xs="12" className="pl-name">
            {machineData?.name}
          </Col>
          <Col xs="12" className="pl-name">
            {machineData?.modifiedDate}
          </Col>
          <Col xs="12" className="pm-metrics">
            Risk assessments: {machineData?.risk_assessments}
          </Col>
          <Col xs="12" className="pm-metrics">
            Tasks assessed: {machineData?.task_assessed}
          </Col>
          <Col xs="12" className="pm-metrics">
            Total tasks: {machineData?.total_tasks}
          </Col>

          <Col xs="12" className="pm-model-edit">
            <Button
              variant="submit-button"
              clickHandler={() => clickHandler(machineData)}
              styles={buttonRoundStyles}
            >
              View Risk Assessments
            </Button>
          </Col>
        </div>
      </Row>
    </div>
  )
}
