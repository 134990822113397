import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const initialState = {
    userList: [],
    isLoading:false
}
const stateReset = {...initialState}

const useListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        // Login
        getUsers: (state, action) => {
            // some state mutation can be performed
            state.isLoading = true
        },
        getUsersSuccess: (state, action) => {
            const response = action.payload
            state.userList = response
            state.isLoading = false;
        },
        getUsersFailure: (state, action) => {
            const response = action.payload
            state.userList = response
            state.isLoading = false;
            // dispatch(showToast({isShow:true, message:response.message,type:'fail'}))
            // toast.error(response.message,{className:'toast-fail'})
        },

        //edit user
        editUser: state => {
            state.isLoading = true;
        },

        editUserSuccess: (state,action) => {
            state.isLoading = false;
            toast.success('User Edited Successfully', {
                autoClose: 2000,
                className: 'toast-success',
            });
        },

        editUserFailure: (state,action) => {
            state.isLoading = false;
        },

        //delete user
        deleteUser: state => {
            state.isLoading = true;
        },

        deleteUserSuccess: (state,action) => {
            state.isLoading = false;
            toast.success('User Deleted Successfully', {
                autoClose: 2000,
                className: 'toast-success',
            });
        },

        deleteUserFailure: (state,action) => {
            state.isLoading = false;
            const response = action.payload;
            toast.error(response.message, {
                autoClose: 2000,
                className: 'toast-fail',
            });
        },

        //approve user
        approveUser: state => {
            state.isLoading = true;
        },
        
        approveUserSuccess: (state,action) => {
            state.isLoading = false
            const response = action.payload;
            toast.success("Action Performed Successfully", {
                autoClose: 2000,
                className: 'toast-success',
            });
        },

        approveUserFailure: (state,action) => {
            state.isLoading = false;
            const response = action.payload;
            toast.error(response.message, {
                autoClose: 2000,
                className: 'toast-fail',
            });
        }
    }
})

export default useListSlice.reducer;
export const { getUsers,
    getUsersSuccess,
    getUsersFailure,
    editUser,
    editUserSuccess,
    editUserFailure,
    deleteUser,
    deleteUserSuccess,
    deleteUserFailure,
    approveUser,
    approveUserSuccess,
    approveUserFailure
} = useListSlice.actions;