import {Form, Formik, useFormikContext} from 'formik';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import Button from '../../../../../components/form/button/button';
import SingleSelectDropdown from '../../../../../components/form/singleSelectDropdown/singleSelectDropdown';
import {assets} from '../../../../../utils/assetsSrc';
import {useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import './importEvent.scss';
import {useDispatch, useSelector} from 'react-redux';
import {getAllRiskAssessmentStateSelector} from '../../../../../redux/selectors/riskAssessment';
import {apiFunctions} from '../../../../../api/functions';
import {toast} from 'react-toastify';

const SideEffectHandler = ({handleRiskAssessmentChange}) => {
  const {values, setFieldValue} = useFormikContext();

  useEffect(() => {
    if (values.riskAssessment) {
      setFieldValue('task', undefined);
      handleRiskAssessmentChange(values.riskAssessment);
    }
  }, [values.riskAssessment]);
};

export default function ImportEvent({
  label,
  show,
  setShow,
  data,
  handleImportEventAction,
}) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const riskAssessmentState = useSelector(getAllRiskAssessmentStateSelector());
  const [riskAssessmentList, setriskAssessmentList] =
    useState(riskAssessmentState);

  // useEffect(() => {
  //     const productLineId = location.state?.selectedProductLine?.id
  //     dispatch(getRiskAssessments({productLineId: productLineId}))
  // },[location])

  useEffect(() => {
    setriskAssessmentList(riskAssessmentState);
  }, []);

  const handleRiskAssessmentChange = raId => {
    setIsLoading(true);
    const payload = {
      riskAssessmentId: raId,
    };
    apiFunctions
      .getRiskAssessmentsTasks(payload)
      .then(resp => {
        setTaskList(resp.data);
      })
      .catch(err => console.log('error occured', err))
      .finally(() => setIsLoading(false));
  };

  const initialValues = {
    riskAssessment: undefined,
    task: undefined,
  };

  const validate = Yup.object({
    riskAssessment: Yup.string().required('Risk Assessment is Required'),
    task: Yup.object().required('Task is Required'),
  });

  const handleSubmit = vals => {
    const fromTaskMappingId = vals.task.task_event_mapping.find(item => {
      if (!data.event_sub_category) {
        if (
          data.event_detail.id === item.event_detail.id &&
          !item.event_sub_category
        ) {
          return item;
        }
      } else {
        if (data.event_sub_category === item.event_sub_category) {
          return item;
        }
      }
    })?.id;
    if (fromTaskMappingId) {
      const payload = {
        to_risk_assessment_id: vals.riskAssessment,
        from_task_mapping_id: fromTaskMappingId,
        to_task_mapping_id: data.id,
      };
      handleImportEventAction(payload);
    } else {
      toast.error('No assessment found', {className: 'toast-fail'});
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="import-event"
      centered
      className="import-event">
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleSubmit}>
          {({
            formik,
            values,
            setFieldValue,
            isSubmitting,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <div className="user-multiselect">
                <SingleSelectDropdown
                  label="Risk assessment"
                  listItems={riskAssessmentList}
                  name="riskAssessment"
                  value={values['riskAssessment']}
                  handleSelectedUsersChange={val => {
                    setFieldValue('riskAssessment', val.id);
                  }}
                  showError={true}
                />
              </div>
              <div className="user-multiselect">
                <SingleSelectDropdown
                  label="Task"
                  listItems={taskList}
                  name="task"
                  value={values['task']}
                  handleSelectedUsersChange={val => {
                    setFieldValue('task', val);
                  }}
                  showError={true}
                />
              </div>
              <div className="action-buttons">
                <Button type="submit" variant="submit-button">
                  Import
                </Button>
              </div>
              <SideEffectHandler
                handleRiskAssessmentChange={handleRiskAssessmentChange}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
