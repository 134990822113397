import { LimitTypesId, accountForId, accountForNames, personellExposedId } from "../../../../../utils/constants";

export const limitsRowData = {
    space: [
        {
            accountForId: accountForId['RANGE_MOVEMENT'],
            accountForName: 'RANGE_MOVEMENT',
            order: 1,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['SPACE_REQUIREMENT'],
            accountForName: 'SPACE_REQUIREMENT',
            order: 2,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['HUMAN_INTERACTION'],
            accountForName: 'HUMAN_INTERACTION',
            order: 3,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['MACHINE_POWER'],
            accountForName: 'MACHINE_POWER',
            order: 4,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        }
    ],
    use: [
        {
            accountForId: accountForId['DIFFERENT_MACHINE'],
            accountForName: 'DIFFERENT_MACHINE',
            order: 1,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['USE_OF_MACHINERY'],
            accountForName: 'USE_OF_MACHINERY',
            order: 2,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
    ],
    time: [
        {
            accountForId: accountForId['LIFE_LIMIT'],
            accountForName: 'LIFE_LIMIT',
            order: 1,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['RECOMMENTDED_SERVICE'],
            accountForName: 'RECOMMENTDED_SERVICE',
            order: 2,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
    ],
    other: [
        {
            accountForId: accountForId['PROPERTIES_MATERIAL'],
            accountForName: 'PROPERTIES_MATERIAL',
            order: 1,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['HOUSE_KEEPING'],
            accountForName: 'HOUSE_KEEPING',
            order: 2,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        },
        {
            accountForId: accountForId['ENVIRONMENTAL'],
            accountForName: 'ENVIRONMENTAL',
            order: 3,
            intended_use: '-',
            resonably_foreseeable_misuse: '-',
            justification: '-',
        }
    ]
}

export const usePersonelData = [
    {
        personellExposedId: personellExposedId['OPERATOR'],
        personellExposedName: 'OPERATOR',
        order: 1,
        intended_use: '-',
        resonably_foreseeable_misuse: '-',
        justification: '-',
    },
    {
        personellExposedId: personellExposedId['MAINTENACE'],
        personellExposedName: 'MAINTENACE',
        order: 2,
        intended_use: '-',
        resonably_foreseeable_misuse: '-',
        justification: '-',
    },
    {
        personellExposedId: personellExposedId['TRAINEES'],
        personellExposedName: 'TRAINEES',
        order: 3,
        intended_use: '-',
        resonably_foreseeable_misuse: '-',
        justification: '-',
    },
    {
        personellExposedId: personellExposedId['GENERAL_PUBLIC'],
        personellExposedName: 'GENERAL_PUBLIC',
        order: 4,
        intended_use: '-',
        resonably_foreseeable_misuse: '-',
        justification: '-',
    }
]