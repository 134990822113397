import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Button from "../../../../../components/form/button/button"
import FileUpload from "../../../../file-upload/fileUpload"
import TextField from "../../../../../components/form/textfield/textfield"
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from "yup"
import { assets } from "../../../../../utils/assetsSrc"
import { useDispatch } from "react-redux"
import { getUsers } from "../../../../../redux/user-list/userListSlice"
import { useSelector } from "react-redux"
import { getUserListSelector } from "../../../../../redux/selectors/userList"
import "./addEditProductLine.scss"

export default function AddEditProductLine({
  label,
  show,
  setShow,
  onclickConfirm,
  action,
  initialFormData
}) {
  const userListState = useSelector(getUserListSelector())
  const dispatch = useDispatch()
  const [userList, setUserList] = useState([])
  const [plImage, setPlImage] = useState("")
  const [selectedUsersList, setSelectedUsersList] = useState([])
  const [formErrors, setFormErrors] = useState({
    plImage: ""
  })

  const { productLine, pImg } = initialFormData
  const initialValues = {
    productLine: productLine ?? ""
  }
  const validate = Yup.object({
    productLine: Yup.string().required("Name is Required")
  })

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  useEffect(() => {
    setUserList(userListState.userList || [])
  }, [userListState])

  const handleFileUpload = file => {
    if (file) {
      setPlImage(file)
      setFormErrors({ ...formErrors, plImage: "" })
    }
  }

  const handleSelectedUsersChange = userList => {
    const users = userList.map(user => user.id)
    setSelectedUsersList(users)
  }

  const handleSubmit = vals => {
    if ( action !== "edit" && !plImage) {
      setFormErrors({ ...formErrors, plImage: "Product Line Image Required" })
      return
    }
    const payload = new FormData()
    payload.append("name", vals.productLine)
    if(plImage)
    payload.append("image", plImage)
    // selectedUsersList.forEach(user => {
    //     payload.append('users', user)
    // })
    onclickConfirm(payload)
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="confirm-cancel"
      centered
      className="add-edit-product-line"
    >
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <div className="file-upload-container">
          <FileUpload handleFileUpload={handleFileUpload} productImg={pImg}/>
          {formErrors.plImage && (
            <div style={{ textAlign: "left", color: "red" }}>
              {formErrors.plImage}
            </div>
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {({ formik, isSubmitting, isValid, errors }) => (
            <Form>
              <div>
                <TextField
                  name="productLine"
                  type="text"
                  label="Name of product lines"
                  placeholder="Name of product line"
                />
              </div>
              {/* <div className='user-access-list'>
                                <MultiSelectDropdown label={'Name'} listItems={userList} handleSelectedUsersChange={handleSelectedUsersChange} />
                            </div> */}
              <div className="action-buttons">
                <Button
                  type="button"
                  clickHandler={() => setShow(false)}
                  variant="secondary-button"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="submit-button">
                  {action === "edit" ? "Update" : "Create"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
