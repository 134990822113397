import { all, fork } from 'redux-saga/effects';
import { loginWatcher, passwordResetWatcher } from './login/loginSaga';
import { signupWatcher } from './signup/signupSaga';
import { productLinesWatcher, productModelsWatcher, riskAssessmentWatcher } from './risk-assessment/riskAssessmentSaga';
import { getUsersWatcher } from './user-list/userListSaga';
import { EventsWatcher, riskAssessmentNestedDataWatcher, riskAssessmentTasksWatcher } from './risk-assessment-core/riskAssessmentCoreSaga';
import { feedbackWatchers } from './feedback/feedbackSaga';
import { passwordWatchers } from './password/passwordSaga';
import { termsConditionsWatchers } from './termsConditions/termsConditionsSaga'

function* rootSaga() {
    yield all([
        // onboarding
        fork(loginWatcher),
        fork(signupWatcher),
        fork(passwordResetWatcher),

        //Risk Assessments
        fork(productLinesWatcher),
        fork(productModelsWatcher),
        fork(riskAssessmentWatcher),
        
        // userlist
        fork(getUsersWatcher),

        // core risk assessments watcher
        fork(riskAssessmentTasksWatcher),

        // Events watcher
        fork(EventsWatcher),

        // Risk Assesment Nested data watcher
        fork(riskAssessmentNestedDataWatcher),

        //feedback watcher
        fork(feedbackWatchers),

        //password watcher
        fork(passwordWatchers),

        //terms condition watcher
        fork(termsConditionsWatchers)
    ])
}

export default rootSaga;