import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { toast } from "react-toastify";

const initialState = {
    isLoading: false,
    feedbackList: []
}

const feedbackSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {

        //send feedback
        sendFeedback: state => {
            state.isLoading = true
        },
        sendFeedbackSuccess: (state, action) => {
            state.isLoading = false
            toast.success("Feedback sent Successfully", {
                autoClose: 2000,
                className: "toast-success"
            })
        },
        sendFeedbackFailure: (state, action) => {
            state.isLoading = false
        },

        //get geedback
        getFeedback: state => {
            state.isLoading = true
        },
        getFeedbackSuccess: (state, action) => {
            const response  = action.payload
            state.isLoading = false
            state.feedbackList = response
        },
        getFeedbackFailure: (state) => {
            state.isLoading = false
        },

        //send feedback reply
        sendFeedbackReply: state => {
            state.isLoading = true
        },

        sendFeedbackReplySuccess: (state,action) => {
            state.isLoading = false
            toast.success("Feedback reply sent Successfully", {
                autoClose: 2000,
                className: "toast-success"
            })
        },

        sendFeedbackReplyFailure: (state, action) => {
            state.isLoading = false
        },

    }
})

export default feedbackSlice.reducer
export const {
    //send feedback lines
    sendFeedback,
    sendFeedbackSuccess,
    sendFeedbackFailure,
    getFeedback,
    getFeedbackSuccess,
    getFeedbackFailure,
    sendFeedbackReply,
    sendFeedbackReplySuccess,
    sendFeedbackReplyFailure

} = feedbackSlice.actions