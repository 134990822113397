import React, { useEffect, useState } from 'react'
import EventRow from './eventRow/eventRow'
import './task-events.scss'

export default function TaskEvents({ eventData }) {
  const [eventList, setEventList] = useState([])
  useEffect(() => {
    const sortedData = eventData.sort((a, b) =>
      Number(a.event_detail?.event_sequence) > Number(b.event_detail?.event_sequence) ? 1 : -1
    )
    setEventList(sortedData)
  }, [eventData])
  return (
    <div className='event-list-wrapper'>
      {eventList.map(event =>
        <EventRow key={event.id} data={event} />
      )}
    </div>
  )
}
