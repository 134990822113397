import { Formik, Form } from "formik"
import { Modal } from "react-bootstrap"
import TextField from "../../components/form/textfield/textfield"
import Button from "../../components/form/button/button"
import { assets } from "../../utils/assetsSrc"
import "./feedback.scss"

export default function ViewFeedback({ label, show, setShow, prefilledData }) {
  const messageInputStyle = {
    height: 88,
    marginTop: 20
  }

  const initialValues = {
    email: prefilledData.email,
    subject: prefilledData.subject,
    message: prefilledData.text
  }

  const handleSubmit = () => {
      {console.log(prefilledData)}
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="User Feedback"
      centered
      className="view-feedback"
    >
      <Modal.Body className="p-5 px-5 text-center">
        <div className="header">
          <p className="label mt-3 mb-4">{label}</p>
          <img onClick={() => setShow(false)} src={assets.closeIcon} />
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {formik => (
            <Form>
              <div className="feedback-form mt-2">
                <TextField
                  type="text"
                  label="Email ID"
                  name="email"
                  placeholder="Enter email"
                  disabled
                />
                <TextField
                  type="text"
                  label="Subject"
                  name="subject"
                  placeholder="Enter Subject"
                  disabled
                />
                <TextField
                  type="text"
                  label="Message"
                  name="message"
                  placeholder="Enter Message"
                  styles={messageInputStyle}
                  disabled
                  isMultiline={true}
                />
                <div className="action-buttons mt-10">
                  <Button type="submit" variant="submit-button">
                    OK
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
