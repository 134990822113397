import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const initialState = {
    // Login user
    isLoggedIn: false,
    loggedInUser: {},
    isLoading: false,
    token: '',
    loginMessage: {
        type:'',
        success: '',
        fail: ''
    },
    // Password reset
    passwordReset: {
        email: {
            type: '',
            success: '',
            fail: ''
        },
        verificationCode: {
            type: '',
            success: '',
            fail: ''
        },
        passwordChange: {
            type: '',
            success: '',
            fail: ''
        }
    }
}
const stateReset = {...initialState}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        // Login
        initLogin: (state, action) => {
            // some state mutation can be performed
            state.isLoading = true
        },
        loginSuccess: (state, action) => {
            const response = action.payload
            state.isLoggedIn = true;
            state.loggedInUser = response.user;
            state.loginMessage = { ...state.loginMessage, success: 'Logged in successfully', type:'success', fail:'' }
            state.token = response.token
            state.isLoading = false;
            // set token in local storage
            localStorage.setItem('token',response.token)
            toast.success('Logged in successfully',{autoClose:2000, className:'toast-success'})
        },
        loginFailure: (state, action) => {
            const response = action.payload
            state.isLoggedIn = false;
            state.loginMessage = { ...state.loginMessage, fail: response.message, type:'fail', success:'' }
            state.isLoading = false;
            // dispatch(showToast({isShow:true, message:response.message,type:'fail'}))
            toast.error(response.message,{className:'toast-fail'})
        },
        logout: (state, action) => {
            state.isLoggedIn = false;
            state.loggedInUser = {};
            state.token = '';
            toast.success('Logged out!!',{autoClose:2000, className:'toast-success'})
        },
        // Password reset
        passwordResetEmailInit: (state, action) => {
        },
        passwordResetEmailSuccess: (state, action) => {
            const payload = action.payload
            const email = {
                success: payload.message,
                fail: '',
                type: payload.type
            }
            state.passwordReset = { ...state.passwordReset, email: {...email} }
            toast.success(payload.message,{className:'toast-success'})
        },
        passwordResetEmailFailure: (state, action) => {
            const payload = action.payload
            const email = {
                success: '',
                fail: payload.message,
                type: payload.type
            }
            state.passwordReset = { ...state.passwordReset, email: {...email} }
            toast.error(payload.message,{className:'toast-fail'})
        },
        // verify code
        passwordResetVerifyCodeInit: (state, action) => {

        },
        passwordResetVerifyCodeSuccess: (state, action) => {
            const payload = action.payload
            const verificationCode = {
                success: payload.message,
                fail: '',
                type: payload.type
            }
            state.passwordReset = { ...state.passwordReset, verificationCode: {...verificationCode} }
            toast.success(payload.message,{className:'toast-success'})
        },
        passwordResetVerifyCodeFailure: (state, action) => {
            const payload = action.payload
            const verificationCode = {
                success: '',
                fail: action.payload.message,
                type: action.payload.type
            }
            state.passwordReset = { ...state.passwordReset, verificationCode: {...verificationCode} }
            toast.error(payload.message,{className:'toast-fail'})
        },
        // new Password
        passwordResetPasswordChangeInit: (state, action) => {

        },
        passwordResetPasswordChangeSuccess: (state, action) => {
            const passwordChange = {
                success: action.payload.message,
                fail: '',
                type: action.payload.type
            }
            state.passwordReset = { ...state.passwordReset, passwordChange: {...passwordChange} }
        },
        passwordResetPasswordChangeFailure: (state, action) => {
            const passwordChange = {
                success: '',
                fail: action.payload.message??'Something went wrong',
                type: action.payload.type
            }
            state.passwordReset = { ...state.passwordReset, passwordChange: {...passwordChange} }
        },
        clearPasswordResetState:(state, action) => {
            state.passwordReset = initialState.passwordReset
        }
    }
})

export default loginSlice.reducer;
export const { initLogin,
    loginSuccess,
    loginFailure,
    passwordResetEmailInit,
    passwordResetEmailSuccess,
    passwordResetEmailFailure,
    passwordResetVerifyCodeInit,
    passwordResetVerifyCodeSuccess,
    passwordResetVerifyCodeFailure,
    passwordResetPasswordChangeInit,
    passwordResetPasswordChangeSuccess,
    passwordResetPasswordChangeFailure,
    clearPasswordResetState,
    logout
} = loginSlice.actions;