import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getLoginStateSelector } from '../../redux/selectors/loginSelector';

import Button from '../../components/form/button/button';
import { assets } from '../../utils/assetsSrc';

import './header.scss';
import { useSelector } from 'react-redux';
import { getUserRoleName } from '../../utils/constants';

export default function Header() {
    const loginState = useSelector(getLoginStateSelector())
    const { isLoggedIn, loggedInUser } = loginState
    const nav = useNavigate();
    return (
        <nav className='navbar'>
            <ul className='header-wrapper'>
                <li className='logo'>
                    <img src={assets.komatsuLogo} />
                </li>
                <li className='buttons'>
                    {!isLoggedIn ?
                        <>
                            <Button variant='secondary-button' clickHandler={() => nav('/login')}>Sign in</Button>
                            <Button variant='submit-button' clickHandler={() => nav('/signup')}>Get Started</Button>
                        </> :
                        <div className='user-profile'>
                            <div>
                                <span>{getUserRoleName(loggedInUser?.role) || ''}</span>
                                <span>{loggedInUser?.name}</span>
                            </div>
                            <div>
                                <img src={assets.profileIcon} />
                            </div>
                        </div>
                    }
                </li>
            </ul>
        </nav>
    )
}
