import React, { useState } from "react"
import "./event-details.scss"
import TextField from "../../../../../components/form/textfield/textfield"
import AddNote from "../../components/add-note/add-note"
import SingleSelectDropdown from "../../../../../components/form/singleSelectDropdown/singleSelectDropdown"
import { statusEnum } from "../../../../../utils/constants"
import ViewInfo from "../../components/view-info/view-info"

const Status = ({ setFieldValue, values, statusValues,  showInfoIcon, readOnly }) => {
  const [showNoteInput, setShowNoteInput] = useState(values.risk_ranking_note?.length>0)

  return (
    <div>
      <div className="row1">
        <div className="label">
          Risk ranking with consideration of current protective measures
        </div>
        <div className="info-icon-left">
          {showInfoIcon ? <ViewInfo /> : <></>}
        </div>
        <div className={"event-status-wrapper"}>
        <span className="event-status">
              {statusValues}
        </span>
        </div>
      </div>
      <AddNote
        showNoteInput={showNoteInput}
        setShowNoteInput={setShowNoteInput}
        name={"risk_ranking_note"}
        value={values.risk_ranking_note}
        setFiledValue={setFieldValue}
        readOnly={readOnly}
      />
      <hr />
    </div>
  )
}

export default Status
