import React from "react"
import { Modal } from "react-bootstrap"
import { assets } from "../../../../../utils/assetsSrc"
import { Form, Formik } from "formik"
import "./assumptions.scss"
import Button from "../../../../../components/form/button/button"
import TextField from "../../../../../components/form/textfield/textfield"

export default function AddEditAssumption({
  show,
  setShow,
  label,
  data,
  saveAssumption,
  id
}) {
  const TextArea = ({ label, height, ...props}) => {
    return (<div style={{position:'relative',marginBottom:'10px'}}>
        <label style={{position:'absolute', left:'10px', top:'-13px', background:'white', padding:'0 4px'}}>{label}</label>
        <textarea wrap="soft" style={{width:'100%',height:height||'190px',resize:'none',padding:'10px'}} {...props} />
    </div>)
}


  const initialValues = {
    [id]: data ?? ""
  }

  const handleSubmit = vals => {
    saveAssumption(vals)
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="add-edit-assumptions"
      centered
      size="lg"
      className="add-edit-assumptions"
    >
      <div className="header">
        <p className="label mt-3 mb-4">{label}</p>
        <img onClick={() => setShow(false)} src={assets.closeIcon} />
      </div>
      <Formik
        initialValues={initialValues}
        // validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {({ formik, values, setFieldValue, isSubmitting, isValid, errors }) => (
          <Form>
            <div>
              {/* <TextField
                name={id}
                type="text"
                label={label}
                placeholder={label}
                styles={{height:100}}
              /> */}
               <TextArea name={id}type='text'
                                label={label}
                                placeholder=""
                                value={values[id]}
                                height="60vh"
                                onChange={(e)=>setFieldValue([id],e.target.value)}
                            />
            </div>
            <div className="action-btns">
              <Button type="submit" variant={"submit-button"}>
                Save
              </Button>
              <Button
                type="button"
                clickHandler={() => setShow(false)}
                variant="secondary-button"
              >
                Close
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
