import React from "react"
import { Modal } from "react-bootstrap"
import { assets } from "../../../../../utils/assetsSrc"
import { Form, Formik } from "formik"
import "./revision-history.scss"
import Button from "../../../../../components/form/button/button"
import TextField from "../../../../../components/form/textfield/textfield"
import * as Yup from "yup"

export default function AddEditRevisionHistory({
  show,
  setShow,
  handleAddEdit,
  revisionHistory
}) {
  const initialValues = {
    revision_number: revisionHistory?.revision_number ?? "",
    date: revisionHistory?.date ?? "",
    description: revisionHistory?.description ?? ""
  }

  const validate = Yup.object({
    revision_number: Yup.string().required("Revision No. is Required"),
    date: Yup.string().required("Date is Required"),
    description: Yup.string().required("Description is Required")
  })

  const handleSubmit = vals => {
    handleAddEdit(vals)
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="add-edit-revision-history"
      centered
      size="md"
      className="add-edit-revision-history"
    >
      <div className="header">
        <p className="label mt-3 mb-4">Revision History</p>
        <img onClick={() => setShow(false)} src={assets.closeIcon} />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {({ formik, values, setFieldValue, isSubmitting, isValid, errors }) => (
          <Form>
            <div>
              <TextField
                name={"revision_number"}
                type="number"
                label={"Revision No."}
                placeholder={"Revision No."}
              />
              <TextField
                name={"date"}
                type="date"
                label={"Date"}
                placeholder={"Date"}
              />
              <TextField
                name={"description"}
                type="text"
                label={"Description"}
                placeholder={"Description"}
                isMultiline={true}
              />
            </div>
            <div className="action-btns">
              <Button type="submit" variant={"submit-button"}>
                Ok
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
