import React from "react"
import "./event-details.scss"
import RadioButton from "../../../../../components/form/rediogroup/radioButton"
import ViewInfo from "../../components/view-info/view-info"

const RadioButtonWithLabel = ({ label, name, setFieldValue, value, showInfoIcon, disabled }) => {
  return (
    <div className="row1">
      <div className="label">{label}</div>
      <div className="info-icon-right">
        {showInfoIcon ? <ViewInfo /> : <></>}
        </div>
      <RadioButton
        value={value}
        onChange={event => {
          setFieldValue(name, event.currentTarget.value)
        }}
        name={name}
        items={[
          { label: "Yes", value: 1 },
          { label: "No", value: 0 }
        ]}
        disabled={disabled}
      />
    </div>
  )
}

export default RadioButtonWithLabel
