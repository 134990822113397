import React from "react"
import { Modal } from "react-bootstrap"
import { assets } from "../../../../../utils/assetsSrc"
import { Form, Formik } from "formik"
import "./attendance-record.scss"
import Button from "../../../../../components/form/button/button"
import TextField from "../../../../../components/form/textfield/textfield"
import * as Yup from "yup"

export default function AddManualEmployee({ show, setShow, handleAdd }) {
  const initialValues = {
    name: "",
    job_title: "",
    date: "",
    duration: ""
  }

  const validate = Yup.object({
    name: Yup.string().required("Name is Required"),
    date: Yup.string().required("Date is Required"),
    job_title: Yup.string().required("Job title is Required"),
    duration: Yup.string().required("Duration is Required")
  })

  const handleSubmit = vals => {
    handleAdd(vals)
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="add-manual-employee"
      centered
      size="md"
      className="add-manual-employee"
    >
      <div className="header">
        <p className="label mt-3 mb-4">Add Employee details manually</p>
        <img onClick={() => setShow(false)} src={assets.closeIcon} />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div>
              <TextField
                name={"name"}
                type="text"
                label={"Name"}
                placeholder={"Name"}
              />
              <TextField
                name={"job_title"}
                type="text"
                label={"Job Title"}
                placeholder={"Job Title"}
              />
              <TextField
                name={"date"}
                type="date"
                label={"Date"}
                placeholder={"Date"}
              />
              <TextField
                name={"duration"}
                type="number"
                label={"Session duration (Min)"}
                placeholder={"Session duration (Min)"}
                min={1}
              />
            </div>
            <div className="action-btns">
              <Button
                type="button"
                clickHandler={() => setShow(false)}
                variant="secondary-button"
              >
                Cancel
              </Button>
              <Button type="submit" variant={"submit-button"}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
