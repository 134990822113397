import { assets } from "../../../../../utils/assetsSrc"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import "./view-info.scss"
import { useState } from "react"

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 400,
    maxWidth: 500,
    maxHeight: 300,
    fontSize: "14px",
    overflow: "auto",
    whiteSpace: "pre-line",
    padding:16
  }
})

export default function ViewInfo({ label }) {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(!open)
  }

  return (
    <div className="view-info-wrapper">
      <span>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <CustomWidthTooltip
                PopperProps={{
                    disablePortal: true,
                }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={label}
            >
              <img src={assets.infoIcon2} onClick={handleTooltipOpen} style={{cursor:"pointer"}}/>
            </CustomWidthTooltip>
          </div>
        </ClickAwayListener>
      </span>
    </div>
  )
}
