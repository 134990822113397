import axios from 'axios';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { errorHandler } from '../../utils/errorHandling';

// API function import
import { apiFunctions } from '../../api/functions';

// Reducer import from userListSlice
import {
    getUsers,
    getUsersSuccess,
    getUsersFailure,
    editUserSuccess,
    editUserFailure,
    deleteUserSuccess,
    deleteUserFailure,
    approveUserSuccess,
    approveUserFailure
} from './userListSlice';

const getUsersAPI = apiFunctions.getUsers;
const editUserAPI = apiFunctions.editUser;
const deleteUserAPI = apiFunctions.deleteUser;
const approveUserAPI = apiFunctions.approveUser;


async function getUsersHandler(payload) {
    return await getUsersAPI(payload);
}

// get users list
function* getUsersList({ payload }) {
    try {
        const result = yield call(getUsersHandler, payload);
        yield put(getUsersSuccess(result.data))
    }
    catch (error) {
        const err = errorHandler(error)
        yield put(getUsersFailure(err))
    }
}

async function editUserHandler(id, payload) {
    return await editUserAPI(id, payload);
}

function* editUser({ payload }) {
    try {
        const result = yield call(editUserHandler, payload.id, payload.payload);
        payload.callback(result);
        yield put(editUserSuccess(result.data))
    }
    catch (error) {
        const err = errorHandler(error)
        yield put(editUserFailure(err))
    }
}

async function deleteUserHandler(id) {
    return await deleteUserAPI(id);
}

function* deleteUser({ payload }) {
    try {
        const result = yield call(deleteUserHandler, payload.id);
        payload.callback(result);
        yield put(deleteUserSuccess(result.data))
    }
    catch (error) {
        const err = errorHandler(error)
        yield put(deleteUserFailure(err))
    }
}

async function approveUserHandler(id, payload) {
    return await approveUserAPI(id, payload);
}

function* approveUser({ payload }) {
    try {
        const result = yield call(approveUserHandler, payload.id, payload.payload);
        payload.callback(result);
        yield put(approveUserSuccess(result.data))
    }
    catch (error) {
        const err = errorHandler(error)
        yield put(approveUserFailure(err))
    }
}


export function* getUsersWatcher() {
    yield takeLatest('userList/getUsers', getUsersList)
    yield takeLatest('userList/editUser', editUser)
    yield takeLatest('userList/deleteUser', deleteUser)
    yield takeLatest('userList/approveUser', approveUser)
    
}