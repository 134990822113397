import React, { useState } from 'react'
import Header from '../header/header'
import { Outlet } from 'react-router-dom';

import './layout.scss';
import Sidebar from '../sidebar/sidebar';

export default function Layout() {
    // const [showTokenExpiry, setShowTokenExpiry] = useState(false)
    // window.onstorage = () => {
    //     if (localStorage.getItem("isTokenExired")) {
    //         setShowTokenExpiry(true)
    //     }
    // }

    return (
        <>
            <div className='main-wrapper'>
                {/* {showTokenExpiry && <div className='token-expiry'>Your session is expired please login again</div>} */}
                <div className='layout-header-wrapper'>
                    <Header />
                </div>
                <div className='content'>
                    <div className='sidebar-menu'>
                        <Sidebar />
                    </div>
                    <div className='dashboard-components-holder'>
                        <Outlet>

                        </Outlet>
                    </div>
                </div>
            </div>
        </>
    )
}
